import React from "react";
import {
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
  Button as Btn,
  Dialog,
  DialogContent,
  Grid,
  Select,
  Divider,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import SidebarMenu from "../SidebarMenu/SidebarMenu";

import Button from "../Button/Button";
import FormInput from "../FormInput/FormInput";
import logo from "../../assets/images/logo.png";

import useStyles, {
  MenuIcon,
  NavbarContainer,
  NavbarList,
  NavbarLogo,
  NavbarLogo_,
  NavbarMobileLogo,
  NavbarIconMobileList,
  NavLinking,
  NavbarLower,
  NavbarLowerList,
  NavbarLink,
  NavbarIconLink,
} from "./styles";

import {
  AccountCircleOutlined,
  FavoriteBorderOutlined,
  Close,
  LockOutlined,
  Phone,
  CorporateFareOutlined,
  EmailOutlined,
  PersonOutlined,
  FeedOutlined,
  BuildOutlined,
  Public,
  InsertPhoto,
  AutoFixNormal,
  AccountCircleRounded,
} from "@mui/icons-material";
import Paragraph from "../Typography/Paragraph";
import "./styles.css";
import { login, register } from "../../API/API";

const Navbar2 = () => {
  const history = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [openSideMenu, setOpenSideMenu] = React.useState({ left: false });

  const [openSideCart, setOpenSideCart] = React.useState({ right: false });

  const [openLogin, setOpenLogin] = React.useState(false);

  const [openSignUp, setOpenSignUp] = React.useState(false);

  // LOGIN & SIGN UP MODAL

  const handleOpenLogin = () => {
    setOpenLogin(true);
    setOpenSignUp(false);
  };

  const handleOpenSignUp = () => {
    setOpenSignUp(true);
    setOpenLogin(false);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleCloseSignUp = () => {
    setOpenSignUp(false);
  };

  const handleDrawerToggle = (anchor, open) => (e) => {
    if (e.type == "keydown" && (e.key == "Tab" || e.key == "Shift")) {
      return;
    }
    setOpenSideMenu({ [anchor]: open });
    setOpenSideCart({ [anchor]: open });
  };

  //

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // HANDLE LOGOUT

  const handleLogout = () => {
    window.localStorage.removeItem("token");
    history("/");
    window.location.reload();
  };

  // OPEN MODAL

  const [openModal, setOpenModal] = React.useState(false);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [sortList, setSortList] = React.useState(1);

  const handleChangeSort = (event) => {
    setSortList(event.target.value);
    console.log(sortList);
  };

  //

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  const [loginData, setLoginData] = React.useState({
    username: "",
    password: "",
  });

  const [registerData, setRegisterData] = React.useState({
    email: "",
    username: "",
    name: "",
    phone: "",
    password: "",
    password2: "",
  });

  const handleChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
    setRegisterData({
      ...registerData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRegister = () => {
    if (
      registerData.email.length == "" ||
      registerData.username.length == "" ||
      registerData.name.length == "" ||
      registerData.phone.length == "" ||
      registerData.password.length == "" ||
      registerData.password2.length == ""
    ) {
      alert("All Field Required");
    }

    register(registerData).then((json) => {
      if (json.status) {
        alert(json.message);
        window.location.reload();
      } else {
        alert(json.message);
      }
    });
  };

  const handleLogin = () => {
    if (
      registerData.username.length == "" ||
      registerData.password.length == ""
    ) {
      alert("All Field Required");
    }

    login(loginData).then((json) => {
      if (json.status) {
        alert(json.message);
        window.location.reload();
      } else {
        alert(json.message);
      }
    });
  };

  return (
    <>
      <NavbarContainer>
        <Container maxWidth="xxl">
          <AppBar position="static">
            <Toolbar>
              {isMobile ? (
                <>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleDrawerToggle("left", true)}
                  >
                    <MenuIcon color="#BA2025" />
                  </IconButton>
                  <NavbarMobileLogo>
                    <Link to="/">
                      <img src={logo} />
                    </Link>
                  </NavbarMobileLogo>
                  <NavbarIconMobileList>
                    {/* <NavbarIconLink onClick={handleDrawerToggle("right", true)}>
                    <ShapeCircle margin={"0 0 0 1rem"}>
                      <Icon src={shoppingcart} />
                    </ShapeCircle>
                    {cart != undefined ? (
                      <Badge>{cart ? cart.length : null}</Badge>
                    ) : (
                      <></>
                    )}
                  </NavbarIconLink> */}
                    {token ? (
                      <NavbarIconLink onClick={handleMenu}>
                        <AccountCircleRounded style={{ color: "#BA2025" }} />
                      </NavbarIconLink>
                    ) : (
                      <NavbarIconLink onClick={handleMenu}>
                        <AccountCircleRounded style={{ color: "#BA2025" }} />
                      </NavbarIconLink>
                    )}
                  </NavbarIconMobileList>

                  <SidebarMenu
                    anchor="left"
                    open={openSideMenu.left}
                    onOpen={handleDrawerToggle("left", true)}
                    onClose={handleDrawerToggle("left", false)}
                    handleDrawerToggle={handleDrawerToggle("left", false)}
                  />
                </>
              ) : (
                <>
                  <NavbarList>
                    <NavbarLower>
                      <NavbarLowerList>
                        <div style={{ display: "flex" }}>
                          <NavbarLogo>
                            <a href="/">
                              <img src={logo} />
                            </a>
                          </NavbarLogo>
                        </div>

                        <div style={{ display: "flex" }}>
                          <NavbarLink color="#BA2025" href="/">
                            Home
                          </NavbarLink>
                          <NavbarLink color="#BA2025" href="/bundle">
                            Bundle
                          </NavbarLink>
                          <NavbarLink color="#BA2025" href="/product">
                            Product
                          </NavbarLink>
                          <NavbarLink
                            color="#BA2025"
                            // href="/review"
                            onClick={() => alert("MISSING PAGE ASK DESIGNER")}
                          >
                            Review
                          </NavbarLink>

                          {token ? (
                            <>
                              <NavbarIconLink onClick={handleMenu}>
                                <AccountCircleRounded
                                  style={{ color: "#BA2025" }}
                                />
                              </NavbarIconLink>
                            </>
                          ) : (
                            <>
                              <a onClick={() => handleOpenLogin()}>
                                <Button
                                  label="Login"
                                  color="#fff"
                                  bg="#BA2025"
                                  bold="600"
                                  br="6px"
                                  style={{
                                    marginRight: 18,
                                    marginLeft: "0rem",
                                  }}
                                />
                              </a>
                              <a onClick={() => handleOpenSignUp()}>
                                <Button
                                  label="Register"
                                  color="#fff"
                                  bg="#BA2025"
                                  bold="600"
                                  br="6px"
                                  style={{
                                    marginRight: 18,
                                    marginLeft: "0rem",
                                  }}
                                />
                              </a>
                            </>
                          )}
                        </div>
                      </NavbarLowerList>
                    </NavbarLower>
                  </NavbarList>
                </>
              )}

              <>
                <Menu
                  id="menu-appbar"
                  elevation={0}
                  // getContentAnchorEl={null}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  // anchorPosition={{
                  //   top: 300,
                  // }}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                >
                  {token ? (
                    <MenuItem>
                      <NavLinking>
                        <div>
                          <Button
                            label="Profile"
                            bg="#BA2025"
                            color="#fff"
                            bold="500"
                            br="4px"
                            pd=".2rem 1rem"
                            size="12px"
                            href="/profile"
                            style={{ marginBottom: ".6rem" }}
                          />

                          <Button
                            label="Sign Out"
                            bg="#BA2025"
                            color="#fff"
                            bold="500"
                            br="4px"
                            pd=".2rem 1rem"
                            size="12px"
                            onClick={() => handleLogout()}
                          />
                        </div>
                      </NavLinking>
                    </MenuItem>
                  ) : (
                    <MenuItem>
                      <NavLinking>
                        <div>
                          <Button
                            label="Login"
                            bg="#BA2025"
                            color="#fff"
                            bold="500"
                            br="4px"
                            pd=".2rem 1rem"
                            size="12px"
                            onClick={() => handleOpenLogin()}
                            style={{ marginBottom: ".6rem" }}
                          />

                          <Button
                            label="Register"
                            bg="#BA2025"
                            color="#fff"
                            bold="500"
                            br="4px"
                            pd=".2rem 1rem"
                            size="12px"
                            onClick={() => handleOpenSignUp()}
                          />
                        </div>
                      </NavLinking>
                    </MenuItem>
                  )}
                </Menu>
              </>
            </Toolbar>
          </AppBar>
        </Container>
        <Dialog
          open={openLogin}
          onClose={() => setOpenLogin(false)}
          sx={{ width: "100%" }}
          scroll="body"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogContent>
            <div className={classes.theSection}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <a onClick={() => setOpenLogin(false)}>
                  <Close style={{ color: "#c5c5c5" }} />
                </a>
              </div>
              <NavbarLogo_>
                <Link to="/">
                  <img src={logo} />
                </Link>
              </NavbarLogo_>
              <br />
              <Paragraph center size="24px">
                Sign in with your email
              </Paragraph>
              <FormInput
                label="Username"
                placeholder="Username"
                border="1px solid #707070"
                name="username"
                onChange={handleChange}
              />
              <FormInput
                label="Password"
                placeholder="Password"
                border="1px solid #707070"
                name="password"
                type="password"
                onChange={handleChange}
              />
              <a
                style={{
                  color: "#BA2025",
                  textDecoration: "underline",
                  display: "block",
                }}
              >
                Forget Password?
              </a>
              <br />
              <Button
                label="Login"
                color="#fff"
                bg="#BA2025"
                br="6px"
                onClick={() => handleLogin()}
              />
              <br />
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Paragraph>
                  Don't Have Account?{" "}
                  <a style={{ color: "#BA2025", textDecoration: "underline" }}>
                    Sign up
                  </a>
                </Paragraph>
              </div>
            </div>
            <br />
          </DialogContent>
        </Dialog>
        <Dialog
          open={openSignUp}
          onClose={() => setOpenSignUp(false)}
          sx={{ width: "100%" }}
          scroll="body"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogContent>
            <div className={classes.theSection}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <a onClick={() => setOpenSignUp(false)}>
                  <Close style={{ color: "#c5c5c5" }} />
                </a>
              </div>
              <NavbarLogo_>
                <Link to="/">
                  <img src={logo} />
                </Link>
              </NavbarLogo_>
              <br />
              <Paragraph center size="24px">
                Register with your email
              </Paragraph>
              <FormInput
                label="Email Address"
                placeholder="Email Address"
                border="1px solid #707070"
                name="email"
                onChange={handleChange}
              />
              <FormInput
                label="Username"
                placeholder="Username"
                border="1px solid #707070"
                name="username"
                onChange={handleChange}
              />
              <FormInput
                label="Name"
                placeholder="Name"
                border="1px solid #707070"
                name="name"
                onChange={handleChange}
              />
              <FormInput
                label="Contact Number"
                placeholder="Contact Number"
                border="1px solid #707070"
                name="phone"
                type="number"
                onChange={handleChange}
              />
              <FormInput
                label="Password"
                placeholder="Password"
                border="1px solid #707070"
                name="password"
                type="password"
                onChange={handleChange}
              />
              <FormInput
                label="Confirmation Password"
                placeholder="Confirmation Password"
                border="1px solid #707070"
                name="password2"
                type="password"
                onChange={handleChange}
              />
              {/* <a
                style={{
                  color: "#BA2025",
                  textDecoration: "underline",
                  display: "block",
                }}
              >
                Forget Password?
              </a> */}
              <br />
              <Button
                label="Register"
                color="#fff"
                bg="#BA2025"
                br="6px"
                onClick={() => handleRegister()}
              />
              <br />
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Paragraph>
                  Don't Have Account?{" "}
                  <a style={{ color: "#BA2025", textDecoration: "underline" }}>
                    Login Here
                  </a>
                </Paragraph>
              </div>
            </div>
            <br />
          </DialogContent>
        </Dialog>
      </NavbarContainer>
    </>
  );
};

export default Navbar2;
