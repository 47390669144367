import React from "react";
import useStyles, { FooterContainer, NavbarLogo, NavbarLink } from "./styles";
import { Container, Grid } from "@mui/material";
import Section from "../Section/Section";

import Paragraph from "../Typography/Paragraph";
import logo from "../../assets/images/logo.png";
import wechat from "../../assets/images/wechat.png";
import telegram from "../../assets/images/telegram.png";
import { Facebook, Instagram, Twitter, WhatsApp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
export default function Footer() {
  const classes = useStyles();
  // CHNAGE LANGUAGE
  const { t, i18n } = useTranslation();
  return (
    <React.Fragment>
      <FooterContainer>
        <Container>
          <Section pd="0">
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6} md={6}>
                <NavbarLogo>
                  <a href="/">
                    <img src={logo} />
                  </a>
                </NavbarLogo>
                <Paragraph color="#fff" style={{ lineHeight: 1.5 }}>
                  {t(
                    "Nutrition cares about you, we provide men skin care products. Alitron consists of 6 all-natural esseences. Protect the strong and poweful talent of men!"
                  )}
                </Paragraph>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={2}>
                <Paragraph
                  color="#FFF"
                  size="24px"
                  bold="700"
                 className={classes._theMt}
                >
                  {t("Company")}
                </Paragraph>
                <NavbarLink color="#fff">{t("PAYMENT INFORMATION")}</NavbarLink>
                <NavbarLink color="#fff">{t("PRIVACY POLICY")}</NavbarLink>
                <NavbarLink color="#fff">{t("RETURN / REFUND")}</NavbarLink>
                <NavbarLink color="#fff">{t("SHIPPING POLICY")}</NavbarLink>
                <NavbarLink color="#fff">{t("TERMS OF USE")}</NavbarLink>
                <NavbarLink color="#fff">{t("COOKIES")}</NavbarLink>
              </Grid> */}
              {/* <Grid item xs={12} sm={6} md={3}>
                <div className={classes.theSocial}>
                 
                </div>
              </Grid> */}
              <Grid item xs={12} sm={6} md={6}>
                <Paragraph
                  color="#fff"
                  size="24px"
                  bold="700"
                  className={classes._theMt}
                >
                  {t("Manufactured by")}:
                </Paragraph>

                {/* <div style={{ display: "flex" }}>
                  <a href="" className={classes._theCircle} target="_blank">
                    <Instagram style={{ color: "#2B2835", margin: "0 1rem" }} />
                  </a>
                  <a href="" className={classes._theCircle} target="_blank">
                    <Facebook style={{ color: "#2B2835" }} />
                  </a>
                  <a href="" className={classes._theCircle} target="_blank">
                    <Twitter style={{ color: "#2B2835" }} />
                  </a>
                  <a href="https://wa.me/01113020138" className={classes._theCircle} target="_blank">
                    <WhatsApp style={{ color: "#2B2835" }} />
                  </a>
                </div> */}
                {/* <Paragraph color="#fff" margin="0 0 .4rem 0">
                  {t("Manufacture by")}:
                </Paragraph> */}
                <Paragraph color="#fff" margin="0 0 .4rem 0">
                  8 Jalan Pemberita U2/49, Temasys Industrial Park 40150 Shah
                  Alam, Selangor, Malaysia.
                </Paragraph>
                {window.localStorage.getItem("country") == "vietnam" && (
                  <>
                    <Paragraph
                      color="#fff"
                      size="24px"
                      bold="700"
                      className={classes._theMt}
                    >
                      {t("Contact Number")}:
                    </Paragraph>

                    <Paragraph color="#fff" margin="0 0 .4rem 0">
                      <a style={{ color: "#FFF" }} href="tel:0907386386">
                        0907386386
                      </a>
                    </Paragraph>
                  </>
                )}
              </Grid>
            </Grid>

            <Paragraph
              size="14px"
              bold="500"
              color="rgba(255,255,255, .5)"
              margin="2rem 0 0 0"
              center
            >
              Copyright © 2023 Alitron Inc. - All rights reserved.
            </Paragraph>
          </Section>
        </Container>
      </FooterContainer>
    </React.Fragment>
  );
}
