import React from "react";
import { BrowserRouter } from "react-router-dom";
import CartContextProvider from "./context/CartContext";
import { I18nextProvider, useTranslation } from "react-i18next";
import Routes from "./Routes";
import useLang from "./hooks/useLang";
import "./App.css";
import Loader from "react-js-loader";
import { StripeProvider } from "react-stripe-elements";
import { get_language } from "./API/API";

function App() {
  const [loading, setLoading] = React.useState(true);

  // const [country, setCountry] = React.useState("");
  // const [lang, setLang] = React.useState("");

  // const getLocation = () => {
  //   if (!localStorage.getItem("country")) {
  //     get_language().then((json) => {
  //       setLang(json.language);
  //       setCountry(json.country);
  //       localStorage.setItem("lang", json.language);
  //       localStorage.setItem("country", json.country);
  //       window.location.reload()
  //     });
  //   }
  // };

  // React.useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setLoading(false);
  //   }, 3000);

  //   getLocation();

  //   return () => clearTimeout(timeoutId);
  // }, [lang, country]);

  // console.log(localStorage.getItem("lang"));

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Loader type="snipper-default" bgColor={"#ba2025"} size={50} />
        </div>
      ) : (
        <StripeProvider apiKey="pk_live_51NL3WeIt4hjzuTGX04gEyXUlosIBMAJvKWCRaQIgBxH81wR8F63oFeghyKdadXdIhEmVGl9qm3svKeU48T7yq4Re008Dp3NpfF">
          <I18nextProvider i18n={useLang}>
            <CartContextProvider>
              <BrowserRouter>
                <Routes></Routes>
              </BrowserRouter>
            </CartContextProvider>
          </I18nextProvider>
        </StripeProvider>
      )}
    </>
  );
}

export default App;
