import React from "react";
import { SectionContainer } from "./styles";

const Section = ({ children, pd, ...others }) => {
  return (
    <SectionContainer {...others}  pd={pd}>
      {children}
    </SectionContainer>
  );
};

export default Section;
