import React, { useState } from "react";
import { Routes as Router, Route } from "react-router-dom";
import HomePage from "./pages/Home/Home";
import BundlePage from "./pages/Bundle/Bundle";
import ProductPage from "./pages/Product/Product";
import ProfilePage from "./pages/Profile/Profile";
import DeliveryPage from "./pages/Delivery/Delivery";
import OrderHistoryPage from "./pages/OrderHistory/OrderHistory";
import PaymentPage from "./pages/Payment/Payment";

export default function Routes() {
  return (
    <Router basename="/my">
      <Route path="/my" element={<HomePage />} />
      <Route path="/my/bundle" element={<BundlePage />} />
      <Route path="/my/product" element={<ProductPage />} />
      <Route path="/my/profile" element={<ProfilePage />} />
      <Route path="/my/delivery" element={<DeliveryPage />} />
      <Route path="/my/orderhistory" element={<OrderHistoryPage />} />
      <Route path="/my/payment/:type/:client_secret/:id" element={<PaymentPage />} />
    </Router>
  );
}