import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { md } from "../../styles/responsive";
// import { ComboboxInput } from "@reach/combobox";

export const config = {
  root: {},
  theContainer: {
    position: "relative",
    width: "90%",
    padding: "1rem",
    boxShadow: "0px 0px 4px 1px rgb(0 0 0 / 8%)",
    margin: "0 auto",
    marginTop: "8rem",
    ...md("sm", {
      width: "50%",
    }),
    ...md("md", {
      width: "30%",
    }),
    ...md("xmd", {
      width: "40%",
    }),
  },
  theButton: {
    color: "white",
    border: "0",
    padding: "12px 16px",
    marginTop: "16px",
    fontWeight: "600",
    cursor: "pointer",
    transition: "all 0.2s ease",
    display: "block",
    boxShadow: "0px 4px 5.5px 0px rgba(0, 0, 0, 0.07)",
    width: "100%",
    background: "#32325d",
    borderRadius: "6px",
  },

  theTabList: {
    position: "relative",
    // padding: "1rem",
  },

  theFlex: {
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
  },

  theBoxActive: {
    position: "relative",
    background: "#32325d",
    padding: "1rem",
    borderRadius: "4px",
    marginBottom: "1rem",
    cursor: "pointer",
    color: "#fff",
    fontWeight: "700",
    width: "34%",
    textAlign: "center",
  },

  theBox: {
    position: "relative",
    background: "#eaeaea",
    padding: "1rem",
    borderRadius: "4px",
    marginBottom: "1rem",
    cursor: "pointer",
    width: "34%",
    textAlign: "center",
    color: "#1e1e1e",
    fontWeight: "500",
  },
};

export default makeStyles(config);
