import React from "react";
import {
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
  Button as Btn,
  Dialog,
  DialogContent,
  Grid,
  Select,
  Divider,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SidebarMenu from "../SidebarMenu/SidebarMenu";

import Button from "../Button/Button";
import FormInput from "../FormInput/FormInput";
import FormInputSelect from "../FormInputSelect/FormInputSelect";
import logo from "../../assets/images/logo.png";

import useStyles, {
  MenuIcon,
  NavbarContainer,
  NavbarList,
  NavbarLogo,
  NavbarLogo_,
  NavbarMobileLogo,
  NavbarIconMobileList,
  NavLinking,
  NavbarLower,
  NavbarLowerList,
  NavbarLink,
  NavbarIconLink,
  // FormInputSelect,
} from "./styles";

import {
  AccountCircleOutlined,
  FavoriteBorderOutlined,
  Close,
  LockOutlined,
  Phone,
  CorporateFareOutlined,
  EmailOutlined,
  PersonOutlined,
  FeedOutlined,
  BuildOutlined,
  Public,
  InsertPhoto,
  AutoFixNormal,
  AccountCircleRounded,
  Language,
} from "@mui/icons-material";
import Paragraph from "../Typography/Paragraph";
import "./styles.css";
import { get_profile, login, register } from "../../API/API";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Navbar = () => {
  const history = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [openSideMenu, setOpenSideMenu] = React.useState({ left: false });

  const [openSideCart, setOpenSideCart] = React.useState({ right: false });

  const [openLogin, setOpenLogin] = React.useState(false);

  const [openSignUp, setOpenSignUp] = React.useState(false);

  // LOGIN & SIGN UP MODAL

  const handleOpenLogin = () => {
    setOpenLogin(true);
    setOpenSignUp(false);
  };

  const handleOpenSignUp = () => {
    setOpenSignUp(true);
    setOpenLogin(false);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleCloseSignUp = () => {
    setOpenSignUp(false);
  };

  const handleDrawerToggle = (anchor, open) => (e) => {
    if (e.type == "keydown" && (e.key == "Tab" || e.key == "Shift")) {
      return;
    }
    setOpenSideMenu({ [anchor]: open });
    setOpenSideCart({ [anchor]: open });
  };

  //

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // HANDLE LOGOUT

  const handleLogout = () => {
    window.localStorage.removeItem("token");
    history("/my");
    window.location.reload();
  };
  //

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  const [loginData, setLoginData] = React.useState({
    username: "",
    password: "",
  });

  const [registerData, setRegisterData] = React.useState({
    email: "",
    username: "",
    name: "",
    phone: "",
    password: "",
    password2: "",
  });

  const handleChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
    setRegisterData({
      ...registerData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRegister = () => {
    if (
      registerData.email.length == "" ||
      registerData.username.length == "" ||
      registerData.name.length == "" ||
      registerData.phone.length == "" ||
      registerData.password.length == "" ||
      registerData.password2.length == ""
    ) {
      alert("All Field Required");
    }

    register(registerData).then((json) => {
      if (json.status) {
        alert("Successfully Registered");
        window.location.reload();
      } else {
        alert(json.message);
      }
    });
  };

  const handleLogin = () => {
    if (loginData.username.length == "" || loginData.password.length == "") {
      alert("All Field Required");
    }

    login(loginData).then((json) => {
      if (json.status) {
        alert("Welcome Back");
        window.location.reload();
      } else {
        alert(json.message);
      }
    });
  };

  const NAVBARLIST = [
    {
      title: "Home",
      link: "",
    },
    // {
    //   title: "Bundle",
    //   link: "bundle",
    // },
    {
      title: "Product",
      link: "product",
    },
  ];

  // CHANGE LANGUAGE
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const handleChangeLang = (e) => {
    i18n.changeLanguage(e.target.value);
    localStorage.setItem("lang", e.target.value);
    // localStorage.getItem("lang")
  };

  React.useEffect(() => {
    localStorage.getItem("lang");
  }, []);

  // PROFILE
  const [profileData, setProfileData] = React.useState("");

  const getProfile = () => {
    get_profile({ token: token }).then((json) => {
      setProfileData(json.data.name);
    });
  };

  React.useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <NavbarContainer>
        <Container maxWidth="xl">
          <AppBar position="static">
            <Toolbar>
              {isMobile ? (
                <>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleDrawerToggle("left", true)}
                  >
                    <MenuIcon color="#fff" />
                  </IconButton>
                  <NavbarMobileLogo>
                    <Link to="/my">
                      <img src={logo} />
                    </Link>
                  </NavbarMobileLogo>
                  <NavbarIconMobileList>
                    {/* <NavbarIconLink onClick={handleDrawerToggle("right", true)}>
                    <ShapeCircle margin={"0 0 0 1rem"}>
                      <Icon src={shoppingcart} />
                    </ShapeCircle>
                    {cart != undefined ? (
                      <Badge>{cart ? cart.length : null}</Badge>
                    ) : (
                      <></>
                    )}
                  </NavbarIconLink> */}
                    {token ? (
                      <NavbarIconLink onClick={handleMenu}>
                        <AccountCircleRounded style={{ color: "#FFF" }} />
                      </NavbarIconLink>
                    ) : (
                      <NavbarIconLink onClick={handleMenu}>
                        <AccountCircleRounded style={{ color: "#FFF" }} />
                      </NavbarIconLink>
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // marginLeft: "1rem",
                      }}
                    >
                      <Language
                        sx={{
                          color: "#FFF",
                        }}
                      />
                      <FormInputSelect
                        mb="0"
                        border="unset"
                        onChange={handleChangeLang}
                        value={i18n.language}
                        style={{
                          color: "#FFF",
                        }}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value="en">EN</MenuItem>

                        <MenuItem value="vn">VN</MenuItem>

                        <MenuItem value="my">MY</MenuItem>

                        <MenuItem value="cn">CN</MenuItem>
                      </FormInputSelect>
                    </div>
                  </NavbarIconMobileList>

                  <SidebarMenu
                    anchor="left"
                    open={openSideMenu.left}
                    onOpen={handleDrawerToggle("left", true)}
                    onClose={handleDrawerToggle("left", false)}
                    handleDrawerToggle={handleDrawerToggle("left", false)}
                  />
                </>
              ) : (
                <>
                  <NavbarList>
                    <NavbarLower>
                      <NavbarLowerList>
                        <div style={{ display: "flex" }}>
                          <NavbarLogo>
                            <a href="/my">
                              <img src={logo} />
                            </a>
                          </NavbarLogo>
                        </div>

                        <div style={{ display: "flex" }}>
                          {/* <NavbarLink color="#fff" href="/">
                            Home
                          </NavbarLink>
                          <NavbarLink color="#fff" href="/bundle">
                            Bundle
                          </NavbarLink>
                          <NavbarLink color="#fff" href="/product">
                            Product
                          </NavbarLink> */}

                          {NAVBARLIST.map((item) => (
                            <NavbarLink
                              key={item.title}
                              color={
                                location.pathname.replace(/[^A-Za-z]+/g, "") ===
                                item.link
                                  ? "#BA2125"
                                  : "#FFF"
                              }
                              bold={
                                location.pathname.replace(/[^A-Za-z]+/g, "") ===
                                item.link
                                  ? "700"
                                  : "normal"
                              }
                              href={"/my/" + item.link}
                            >
                              {t(item.title)}
                            </NavbarLink>
                          ))}

                          {token ? (
                            <>
                              <NavbarIconLink onClick={handleMenu}>
                                <AccountCircleRounded
                                  style={{ color: "#FFF", marginRight: "12px" }}
                                />
                                <Paragraph color="#FFF">{profileData}</Paragraph>
                              </NavbarIconLink>
                            </>
                          ) : (
                            <>
                              <a onClick={() => handleOpenLogin()}>
                                <Button
                                  label={t("Login")}
                                  color="#fff"
                                  bg="#BA2025"
                                  bold="600"
                                  br="50px"
                                  pd=".6rem 2rem"
                                  style={{
                                    marginRight: 18,
                                    marginLeft: "0rem",
                                  }}
                                />
                              </a>
                              <a onClick={() => handleOpenSignUp()}>
                                <Button
                                  label={t("Register")}
                                  color="#fff"
                                  bg="#BA2025"
                                  bold="600"
                                  br="50px"
                                  pd=".6rem 2rem"
                                  style={{
                                    marginRight: 18,
                                    marginLeft: "0rem",
                                  }}
                                />
                              </a>
                            </>
                          )}

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // marginLeft: "1rem",
                            }}
                          >
                            <Language
                              sx={{
                                color: "#FFF",
                              }}
                            />
                            <FormInputSelect
                              mb="0"
                              border="unset"
                              onChange={handleChangeLang}
                              value={i18n.language}
                              style={{
                                color: "#FFF",
                              }}
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                            >
                              <MenuItem value="en">EN</MenuItem>

                              <MenuItem value="vn">VN</MenuItem>

                              <MenuItem value="my">MY</MenuItem>

                              <MenuItem value="cn">CN</MenuItem>
                            </FormInputSelect>
                          </div>
                        </div>
                      </NavbarLowerList>
                    </NavbarLower>
                  </NavbarList>
                </>
              )}

              <>
                <Menu
                  id="menu-appbar"
                  elevation={0}
                  // getContentAnchorEl={null}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  // anchorPosition={{
                  //   top: 300,
                  // }}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                >
                  {token ? (
                    <MenuItem>
                      <NavLinking>
                        <div>
                          <Button
                            label={t("Profile")}
                            bg="#BA2025"
                            color="#fff"
                            bold="500"
                            br="4px"
                            pd=".2rem 1rem"
                            size="12px"
                            href="/my/profile"
                            style={{ marginBottom: ".6rem" }}
                          />

                          <Button
                            label={t("Sign Out")}
                            bg="#BA2025"
                            color="#fff"
                            bold="500"
                            br="4px"
                            pd=".2rem 1rem"
                            size="12px"
                            onClick={() => handleLogout()}
                          />
                        </div>
                      </NavLinking>
                    </MenuItem>
                  ) : (
                    <MenuItem>
                      <NavLinking>
                        <div>
                          <Button
                            label="Login"
                            bg="#BA2025"
                            color="#fff"
                            bold="500"
                            br="4px"
                            pd=".2rem 1rem"
                            size="12px"
                            onClick={() => handleOpenLogin()}
                            style={{ marginBottom: ".6rem" }}
                          />

                          <Button
                            label="Register"
                            bg="#BA2025"
                            color="#fff"
                            bold="500"
                            br="4px"
                            pd=".2rem 1rem"
                            size="12px"
                            onClick={() => handleOpenSignUp()}
                          />
                        </div>
                      </NavLinking>
                    </MenuItem>
                  )}
                </Menu>
              </>
            </Toolbar>
          </AppBar>
        </Container>
        <Dialog
          open={openLogin}
          onClose={() => setOpenLogin(false)}
          sx={{ width: "100%" }}
          scroll="body"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogContent>
            <div className={classes.theSection}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <a onClick={() => setOpenLogin(false)}>
                  <Close style={{ color: "#c5c5c5" }} />
                </a>
              </div>
              <NavbarLogo_>
                <Link to="/">
                  <img src={logo} />
                </Link>
              </NavbarLogo_>
              <br />
              <Paragraph center size="24px">
                {t("Sign in with your email")}
              </Paragraph>
              <FormInput
                label={t("Username")}
                placeholder={t("Username")}
                border="1px solid #707070"
                name="username"
                onChange={handleChange}
              />
              <FormInput
                label={t("Password")}
                placeholder={t("Password")}
                border="1px solid #707070"
                name="password"
                type="password"
                onChange={handleChange}
              />
              {/* <a
                style={{
                  color: "#BA2025",
                  textDecoration: "underline",
                  display: "block",
                }}
              >
                Forget Password?
              </a> */}
              <br />
              <Button
                label={t("Login")}
                color="#fff"
                bg="#BA2025"
                br="6px"
                onClick={() => handleLogin()}
              />
              <br />
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Paragraph>
                  {t("Don't Have Account?")} {""}
                  <a
                    onClick={() => handleOpenSignUp()}
                    style={{ color: "#BA2025", textDecoration: "underline" }}
                  >
                    {t("Sign up")}
                  </a>
                </Paragraph>
              </div>
            </div>
            <br />
          </DialogContent>
        </Dialog>
        <Dialog
          open={openSignUp}
          onClose={() => setOpenSignUp(false)}
          sx={{ width: "100%" }}
          scroll="body"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogContent>
            <div className={classes.theSection}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <a onClick={() => setOpenSignUp(false)}>
                  <Close style={{ color: "#c5c5c5" }} />
                </a>
              </div>
              <NavbarLogo_>
                <Link to="/">
                  <img src={logo} />
                </Link>
              </NavbarLogo_>
              <br />
              <Paragraph center size="24px">
                {t("Register with your email")}
              </Paragraph>
              <FormInput
                label={t("Email Address")}
                placeholder={t("Email Address")}
                border="1px solid #707070"
                name="email"
                onChange={handleChange}
              />
              <FormInput
                label={t("Username")}
                placeholder={t("Username")}
                border="1px solid #707070"
                name="username"
                onChange={handleChange}
              />
              <FormInput
                label={t("Name")}
                placeholder={t("Name")}
                border="1px solid #707070"
                name="name"
                onChange={handleChange}
              />
              <FormInput
                label={t("Contact Number")}
                placeholder={t("Contact Number")}
                border="1px solid #707070"
                name="phone"
                type="number"
                onChange={handleChange}
              />
              <FormInput
                label={t("Password")}
                placeholder={t("Password")}
                border="1px solid #707070"
                name="password"
                type="password"
                onChange={handleChange}
              />
              <FormInput
                label={t("Confirmation Password")}
                placeholder={t("Confirmation Password")}
                border="1px solid #707070"
                name="password2"
                type="password"
                onChange={handleChange}
              />
              {/* <a
                style={{
                  color: "#BA2025",
                  textDecoration: "underline",
                  display: "block",
                }}
              >
                Forget Password?
              </a> */}
              <br />
              <Button
                label={t("Register")}
                color="#fff"
                bg="#BA2025"
                br="6px"
                onClick={() => handleRegister()}
              />
              <br />
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Paragraph>
                  {t("Already Have Account?")} {""}
                  <a style={{ color: "#BA2025", textDecoration: "underline" }}>
                    {t("Login Here")}
                  </a>
                </Paragraph>
              </div>
            </div>
            <br />
          </DialogContent>
        </Dialog>
      </NavbarContainer>
    </>
  );
};

export default Navbar;
