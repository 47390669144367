import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import useStyles, { IncrementAction } from "./styles";
import { Box, Container, Grid } from "@mui/material";
import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";

import prod001 from "../../assets/images/prod001.png";
import Footer from "../../components/Footer/Footer";
import useCart from "../../hooks/useCart";
import { Add, Remove } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { get_product } from "../../API/API";
import { useTranslation } from "react-i18next";

export default function Bundle() {


  console.log('this is bundle page');

  const classes = useStyles();
  const navigate = useNavigate();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Bundle]);

  // CHNAGE LANGUAGE
  const { t, i18n } = useTranslation();

  const { addProduct, clearCart } = useCart();
  const [prodList, setProdList] = React.useState([]);

  const getProduct = () => {
    get_product({
      country: "malaysia",
      currency: "MYR",
    }).then((json) => {
      setProdList(json.data);
    });
  };

  React.useEffect(() => {
    getProduct();
  }, []);

  const handleBuyNow = (item) => {
    let bundleList = {
      name: item.name,
      price: item.price,
      quantity: 1,
      images: item.image,
      product_id: item.product_id,
    };
    addProduct(bundleList);
    let array = [];
    array.push(bundleList);
    window.localStorage.setItem("bundleList", JSON.stringify(array));
    navigate("/my/delivery");
  };
  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <Section pd="4rem 0">
          <Container>
            <Paragraph
              color="#2a2835"
              size="50px"
              bold="700"
              margin="0 0 2rem 0"
              center
            >
              {t("Choose Your Bundle")}
            </Paragraph>
            <Grid container spacing={3}>
              {prodList.map((item, index) => (
                <Grid item xs={12} sm={6} md={6}>
                  <Box
                    className={classes._theCard}
                    sx={{ background: index % 2 === 0 ? "#F8F8F8" : "#FFC700" }}
                  >
                    <div className={classes.theProdIMG}>
                      <img src={prod001} />
                    </div>
                    <Paragraph
                      color="#2a2835"
                      size="39px"
                      bold="500"
                      margin="0 0 .4rem 0"
                    >
                      {item.name}
                    </Paragraph>
                    <Paragraph margin="0 0 1rem 0" size="16px">
                      {item.description}
                    </Paragraph>
                    <Box
                      sx={{
                        display: { xs: "block", lg: "flex" },
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {item.country == "vietnam" ? (
                        <Paragraph
                          className={classes._theMb}
                          color={index % 2 === 0 ? "#9c831f" : "#2a2835"}
                          bold="600"
                          size="25px"
                          center
                        >
                          USD {item.price}
                        </Paragraph>
                      ) : (
                        <Paragraph
                          className={classes._theMb}
                          color={index % 2 === 0 ? "#9c831f" : "#2a2835"}
                          bold="600"
                          size="25px"
                          center
                        >
                          RM {item.price}
                        </Paragraph>
                      )}

                      <Button
                        className={classes._theMb}
                        label={t("BUY NOW")}
                        color="#fff"
                        bg="#BA2025"
                        pd=".6rem 2.5rem"
                        br="50px"
                        // href="/bundle"
                        onClick={() => handleBuyNow(item)}
                      />
                      <Button
                        className={classes._theMb}
                        label={t("GET DETAIL")}
                        color="#BA2025"
                        box="1px solid #BA2025"
                        bold="600"
                        pd=".6rem 2.5rem"
                        br="50px"
                        href="/my/product"
                      />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Section>
      </Container>

      <Footer />
    </React.Fragment>
  );
}
