import axios from "axios";
const host = "https://alitron.net/admin/";
const access_endpoint_link = host + "API/";

// const headers = {
//   Accept: "application/json, text/plain, */*",
//   "Content-Type": "application/x-www-form-urlencoded",
//   // 'authorization': `Bearer ${token}`
// };

const format_request = (postparam) => {
  if (postparam) {
    let token = window.localStorage.getItem("token");
    if (token) {
      postparam["token"] = token;
    }
    
    // let lang = window.localStorage.getItem("lang");
    // if (lang) {
    //   postparam["lang"] = lang;
    // }

    var country = "malaysia";
    window.localStorage.setItem("country", country);
    postparam["country"] = country;


    var currency = "MYR";
    postparam["currency"] = currency;
    
    // REGION
    // var region = "MY";
    // let region_res = window.localStorage.getItem("country");
    // if (region_res) {
    //   region = region_res;
    // }
    // if (region) {
    //   postparam["country"] = region;
    // }


        // var region = "MY";
    // let region_res = window.localStorage.getItem("country");
    // if (region_res) {
    //   region = region_res;
    // }
    // if (region) {
    //   postparam["country"] = region;
    // }

    // if (window.localStorage.getItem("") === "MY") {
    //   var Lang = "en";
    //   let Lang_res = window.localStorage.setItem("lang", Lang);
    //   if (Lang_res) {
    //     Lang = Lang_res;
    //   }
    //   if (Lang) {
    //     postparam["lang"] = Lang;
    //   }
    //   return headers;
    // }
    // if (window.localStorage.getItem("countryCode") === "SG") {
    //   var Lang = "vn";
    //   let Lang_res = window.localStorage.setItem("lang", Lang);
    //   if (Lang_res) {
    //     Lang = Lang_res;
    //   }
    //   if (Lang) {
    //     postparam["lang"] = Lang;
    //   }
    //   return headers;
    // }

    // var Lang = window.localStorage.getItem("lang");
    // let Lang_res = window.localStorage.getItem("lang");
    // if (Lang_res) {
    //   Lang = Lang_res;
    // }
    // if (Lang) {
    //   postparam["lang"] = Lang;
    // }
  }
  // var Lang = "en";
  // let Lang_res = window.localStorage.getItem("lang");
  // if (Lang_res) {
  //   Lang = Lang_res;
  // }
  // if (Lang) {
  //   postparam["lang"] = Lang;
  // }

  // const axiosLocation = async () => {
  //   var Lang = window.localStorage.getItem("lang");
  //   console.log(Lang)
  //   if (Lang) {
  //     postparam["lang"] = Lang;
  //   }
  //   try {
  //     const response = await axios.get("https://ipapi.co/json/", { headers });
  //     window.localStorage.setItem("countryCode", response.data.country_code);

  //     if (response.data.country_code === "MY") {
  //       var Lang = "en";
  //       let Lang_res = window.localStorage.setItem("lang", Lang);
  //       if (Lang_res) {
  //         Lang = Lang_res;
  //       }
  //       if (Lang) {
  //         postparam["lang"] = Lang;
  //       }

  //       // Refresh the page
  //       window.location.reload();
  //     }
  //     if (response.data.country_code === "SG") {
  //       var Lang = "vn";
  //       let Lang_res = window.localStorage.setItem("lang", Lang);
  //       if (Lang_res) {
  //         Lang = Lang_res;
  //       }
  //       if (Lang) {
  //         postparam["lang"] = Lang;
  //       }

  //       // Refresh the page
  //       window.location.reload();
  //     }
  //   } catch (error) {
  //     console.error("Error axiosing location:", error);
  //   }
  // };

  // // Call axiosLocation() when the page loads
  // axiosLocation();



  let body = "";
  for (let k in postparam) {
    body += encodeURI(k) + "=" + encodeURI(postparam[k]) + "&";
  }
  var postOptions = {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/x-www-form-urlencoded",
      // "Access-Control-Allow-Origin": "*"
    },
    body: body,
  };

  return postOptions;
};

export const login = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "login",
    format_request(postparam)
  );
  var json = await response.json();
  if (json.status) {
    window.localStorage.setItem("token", json.data.token);
    window.localStorage.setItem("user_id", json.data.user_id);
    return json;
  } else {
    if (json.message == "AUTH FAIL") {
      window.localStorage.clear();
      alert("Authentication broken");
      window.location.href = "/";
    } else {
      // alert(json.message);
    }
    return json;
  }
};

export const register = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "register",
    format_request(postparam)
  );
  var json = await response.json();
  if (json.status) {
    // console.log(json.data);
    window.localStorage.setItem("token", json.data.token);
    window.localStorage.setItem("user_id", json.data.user_id);
    return json;
  } else {
    if (json.message == "AUTH FAIL") {
      window.localStorage.clear();
      alert("Authentication broken");
      window.location.href = "/";
    } else {
      // alert(json.message);
    }
    return json;
  }
};

export const get_product = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_product",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_profile = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_profile",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_order_history = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_order_history",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_address_book = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_address_book",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const edit_profile = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "edit_profile",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const change_password = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "change_password",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const add_address_book = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "add_address_book",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const address_book_detail = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "address_book_detail",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const edit_address_book = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "edit_address_book",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const delete_address_book = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "delete_address_book",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_order_detail = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_order_detail ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const submit_order = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "submit_order ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const check_promocode_validity = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "check_promocode_validity",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_language = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_language",
    format_request(postparam)
  );
  var json = await response.json();
  // window.localStorage.setItem("lang", json.language);
  // window.localStorage.setItem("country", json.country);
  return json;
};


export const get_language_by_post = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_language_by_post",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};
