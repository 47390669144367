import React from "react";
import useStyles from "./styles";
import Footer from "../../components/Footer/Footer";
import Navbar2 from "../../components/Navbar2/Navbar2";
import Paragraph from "../../components/Typography/Paragraph";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Section from "../../components/Section/Section";
import { ArrowBackIos } from "@mui/icons-material";

export default function OrderHistory() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Navbar2 />
      <div className={classes.theBGRED}>
        <Paragraph center color="#fff" bold="600" size="24px">
          ORDER HISTORY
        </Paragraph>
      </div>
      <br />
      <Container>
        <Section>
          <a
            href="/profile"
            style={{ color: "#BA2025", display: "block", marginBottom: "1rem" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ArrowBackIos />
              BACK TO PROFILE
            </div>
          </a>
          <br/>
          
          <TableContainer>
            <Table>
              <TableHead className={classes.theHead}>
                <TableRow>
                  <TableCell style={{ color: "#fff" }}>Date Created</TableCell>
                  <TableCell style={{ color: "#fff" }}>Order ID</TableCell>
                  <TableCell style={{ color: "#fff" }}>Total Amount</TableCell>
                  <TableCell style={{ color: "#fff" }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>03/07/2022</TableCell>
                  <TableCell>#A000001</TableCell>
                  <TableCell>RM 1000.00</TableCell>
                  <TableCell>IN PROGRESS</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Section>
      </Container>

      <Footer />
    </React.Fragment>
  );
}
