import { ArrowBackIos, Close } from "@mui/icons-material";
import {
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
} from "@mui/material";
import React from "react";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/FormInput/FormInput";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles from "./styles";

import prodimg001 from "../../assets/images/prodimg001.png";
import Button from "../../components/Button/Button";
import useCart from "../../hooks/useCart";
import {
  add_address_book,
  check_promocode_validity,
  get_address_book,
  submit_order,
} from "../../API/API";
import { useTranslation } from "react-i18next";
import Loader from "react-js-loader";
import { useNavigate } from "react-router-dom";

export default function Delivery() {
  const classes = useStyles();
  const [token, setToken] = React.useState([]);

  const navigate = useNavigate();

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  // CHNAGE LANGUAGE
  const { t, i18n } = useTranslation();

  const { getCart, setCartFunction, removeProduct, getTotalCart } = useCart();

  const getcart = JSON.stringify(getCart());

  const [cart, setCart] = React.useState(getCart);

  const productCartList = getcart.replace(/\\/g, "");

  // const cartItem = window.localStorage.getItem("cart");

  React.useEffect(() => {
    setCart(getCart());
  }, [getCart]);

  const [shippingFee, setShippingFee] = React.useState(10);

  const [promoCode, setPromoCode] = React.useState({
    promocode: "",
  });

  // ADDRESS LIST

  const [addressBook, setAddressBook] = React.useState([]);

  const getAddressBook = () => {
    get_address_book({ token: token }).then((json) => {
      setAddressBook(json.data);
      // setChooseAddress(json.data[0].user_address_id)
    });
  };

  const [chooseAddress, setChooseAddress] = React.useState(null);

  const handleChooseAddresss = (e) => {
    setChooseAddress(e);
  };

  React.useEffect(() => {
    getAddressBook();
  }, [chooseAddress]);

  React.useEffect(() => {
    if (addressBook) {
      const defaultAddress = addressBook.find(
        (item) => item.is_default === "1"
      );
      if (defaultAddress) {
        setChooseAddress(defaultAddress.user_address_id);
      }
    }
  }, [addressBook]);

  //

  const handleChange = (e) => {
    setAddressData({
      ...addressData,
      [e.target.name]: e.target.value,
    });
  };

  const [promoAmount, setPromodeAmount] = React.useState(0);

  const handleChangePromo = (e) => {
    setPromoCode({
      ...promoCode,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckPromo = () => {
    check_promocode_validity(promoCode).then((json) => {
      if (json.status) {
        alert(json.message);
        setPromodeAmount(json.amount);
      } else {
        alert(json.message);
      }
    });
  };

  const [addressData, setAddressData] = React.useState({
    name: "",
    address: "",
    state: "",
    country: "",
    phone: "",
    city: "",
    postcode: "",
    email: "",
  });

  const [isLoading, setIsLoading] = React.useState(false);

  const handlePayNowToken = (checkoutType) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    var cartLength = getCart().length;
    if (cartLength == 0) {
      alert("Your cart is empty.");
      setIsLoading(false);
      return false;
    }

    // if (chooseAddress == null || undefined) {
    //   alert("Please Choose Shipping Address");
    //   setIsLoading(false);
    //   return;
    // }

    let postparam = {
      token: token,
      user_address_id: chooseAddress,
      cart: productCartList,
      promocode: promoCode.promocode,
      payment_type: checkoutType,
      country: "malaysia",
      currency: "MYR"
    };

    // console.log(postparam);

    submit_order(postparam).then((json) => {
      if (json.status) {
        // alert(json.message);
        // navigate("/payment/" + checkoutType, {
        //   state: {
        //     secret: json.data.client_secret,
        //     payment_id: json.data.id,
        //   },
        // });
        navigate(
          "/my/payment/" +
            checkoutType +
            "/" +
            json.data.client_secret +
            "/" +
            json.data.id
        );

        setIsLoading(false);
      } else {
        alert(json.message);
        setIsLoading(false);
      }
    });
  };

  const handlePayNow = (checkoutType) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    var cartLength = getCart().length;
    if (cartLength == 0) {
      alert("Your cart is empty.");
      setIsLoading(false);
      return false;
    }

    if (
      addressData.name.length == "" ||
      addressData.phone.length == "" ||
      addressData.address.length == "" ||
      addressData.city.length == "" ||
      addressData.postcode.length == "" ||
      addressData.state.length == "" ||
      addressData.country.length == "" ||
      addressData.email.length == ""
    ) {
      alert("All Field Required");
      return;
    }

    let postparam = {
      cart: productCartList,
      promocode: promoCode.promocode,
      token: "",
      ...addressData,
      payment_type: checkoutType,
      country: "malaysia",
      currency: "MYR"
    };

    submit_order(postparam).then((json) => {
      if (json.status) {
        // alert(json.message);
        // navigate("/payment/" + checkoutType, {
        //   state: {
        //     secret: json.data.client_secret,
        //     payment_id: json.data.id,
        //   },
        // });

        navigate(
          "/my/payment/" +
            checkoutType +
            "/" +
            json.data.client_secret +
            "/" +
            json.data.id
        );

        // window.open("")

        setIsLoading(false);
      } else {
        alert(json.message);
        setIsLoading(false);
      }
    });
  };

  // ADD NEW ADDRESS

  const [checked, setChecked] = React.useState(true);

  const [defaultAddress, setDefaultAddress] = React.useState(1);

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);

    console.log(event.target.checked);

    if (event.target.checked == true) {
      setDefaultAddress(1);
    }

    if (event.target.checked == false) {
      setDefaultAddress(0);
    }
  };

  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleAddAddress = () => {
    if (
      addressData.name.length == "" ||
      addressData.phone.length == "" ||
      addressData.address.length == "" ||
      addressData.city.length == "" ||
      addressData.postcode.length == "" ||
      addressData.state.length == "" ||
      addressData.country.length == ""
    ) {
      alert("All Field Required");
    } else {
      add_address_book({
        ...addressData,
        token: token,
        is_default: defaultAddress,
      }).then((json) => {
        if (json.status) {
          // alert(json.message);
          window.location.reload();
        } else {
          // alert(json.message);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Navbar />
      <Container>
        <Section pd="4rem 0">
          <Grid container spacing={3}>
            {token ? (
              <Grid item xs={12} sm={12} md={6}>
                <Paragraph bold="600" margin="0 0 1rem 0">
                  {t("Choose Your Shipping Address")}
                </Paragraph>
                {addressBook.map((item) => (
                  <a
                    // className={classes.theListAddress}
                    className={
                      item.user_address_id == chooseAddress
                        ? classes.theListAddress
                        : classes.theListAddressDefault
                    }
                    // chooseAddress={item.user_address_id}
                    onClick={() => handleChooseAddresss(item.user_address_id)}
                  >
                    <div>
                      <Paragraph className="theEllipsis" margin=" 0 0 .6rem 0">
                        {item.name}
                      </Paragraph>
                    </div>
                    <Paragraph>
                      {" "}
                      {item.address}, {item.city}, {item.postcode},{item.state},{" "}
                      {item.contry}
                    </Paragraph>
                    <Paragraph size="14px" color="#637381">
                      {item.phone}
                    </Paragraph>
                  </a>
                ))}
                <br />
                <Button
                  label={t("Add New Address")}
                  color="#fff"
                  bg="#BA2025"
                  br="50px"
                  pd=".6rem 2.5rem"
                  onClick={() => handleOpenModal()}
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Paragraph bold="600">{t("Contact Information")}</Paragraph>
                  {/* <Paragraph>
                    Already have account?{" "}
                    <a
                      style={{
                        color: "#BA2025",
                        textDecoration: "underline",
                      }}
                    >
                      Log in
                    </a>
                  </Paragraph> */}
                </div>
                <FormInput
                  placeholder={t("Email")}
                  border="1px solid #707070"
                  name="email"
                  onChange={handleChange}
                />
                <br />
                <Paragraph bold="600">{t("Shipping Address")}</Paragraph>

                <FormInput
                  placeholder={t("Name")}
                  border="1px solid #707070"
                  name="name"
                  onChange={handleChange}
                />
                <FormInput
                  placeholder={t("Phone")}
                  border="1px solid #707070"
                  name="phone"
                  onChange={handleChange}
                />

                <FormInput
                  placeholder={t("Address")}
                  border="1px solid #707070"
                  name="address"
                  onChange={handleChange}
                />

                <FormInput
                  placeholder={t("Postcode")}
                  border="1px solid #707070"
                  name="postcode"
                  type="number"
                  onChange={handleChange}
                />
                <FormInput
                  placeholder={t("City")}
                  border="1px solid #707070"
                  name="city"
                  onChange={handleChange}
                />
                <FormInput
                  placeholder={t("State/Territoty")}
                  border="1px solid #707070"
                  name="state"
                  onChange={handleChange}
                />
                <FormInput
                  placeholder={t("Country")}
                  border="1px solid #707070"
                  name="country"
                  onChange={handleChange}
                />

                <br />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ArrowBackIos style={{ color: "#BA2025" }} />
                  <a href="/" style={{ color: "#BA2025", fontWeight: "700" }}>
                    {t("Return Home")}
                  </a>
                </div>
                <br />
                <br />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={6}>
              {cart != undefined && cart.length > 0
                ? cart.map((item) => (
                    <div className={classes.theFlex}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div className={classes.theProdIMG}>
                          <img src={item.images} />
                        </div>
                        <Paragraph>{item.name}</Paragraph>
                      </div>
                      {window.localStorage.getItem("country") == "vietnam" ? (
                        <Paragraph style={{ textAlign: "right", width: "25%" }}>
                          USD {item.price}
                        </Paragraph>
                      ) : (
                        <Paragraph style={{ textAlign: "right", width: "25%" }}>
                          RM {item.price}
                        </Paragraph>
                      )}

                      {/* <a style={{ display: "block", marginLeft: "1rem" }}>
                            <Close />
                          </a> */}
                    </div>
                  ))
                : null}
              <div className={classes.theFlex_}>
                <div className={classes.theWidth}>
                  <FormInput
                    placeholder={t("Promo Code")}
                    border="1px solid #707070"
                    style={{ width: "100%" }}
                    name="promocode"
                    onChange={handleChangePromo}
                  />
                </div>

                <Button
                  label={t("APPLY")}
                  color="#fff"
                  bg="#BA2025"
                  // br="6px"
                  pd=".6rem 2.5rem"
                  onClick={() => handleCheckPromo()}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                  paddingTop: "1rem",
                  borderTop: "1px solid #d7d7d7",
                }}
              >
                <Paragraph size="1.2rem">{t("Subtotal")}</Paragraph>
                {window.localStorage.getItem("country") == "vietnam" ? (
                  <Paragraph bold="500" size="1.2rem">
                    USD {getTotalCart().toFixed(2)}
                  </Paragraph>
                ) : (
                  <Paragraph bold="500" size="1.2rem">
                    RM {getTotalCart().toFixed(2)}
                  </Paragraph>
                )}
              </div>

              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <Paragraph size="1.2rem">{t("Shipping Fee")}</Paragraph>
                <Paragraph bold="500" size="1.2rem">
                  RM 10.00
                </Paragraph>
              </div> */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <Paragraph size="1.2rem">{t("Discount")}</Paragraph>
                {window.localStorage.getItem("country") == "vietnam" ? (
                  <Paragraph bold="500" color="#ba2025" size="1.2rem">
                    - USD {promoAmount}
                  </Paragraph>
                ) : (
                  <Paragraph bold="500" color="#ba2025" size="1.2rem">
                    - RM {promoAmount}
                  </Paragraph>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                  paddingTop: "1rem",
                  borderTop: "1px solid #d7d7d7",
                }}
              >
                <Paragraph size="1.2rem">{t("Total")}</Paragraph>
                {window.localStorage.getItem("country") == "vietnam" ? (
                  <Paragraph color="#BA2025" bold="700" size="1.2rem">
                    USD {parseFloat(getTotalCart() - promoAmount).toFixed(2)}
                  </Paragraph>
                ) : (
                  <Paragraph color="#BA2025" bold="700" size="1.2rem">
                    RM {parseFloat(getTotalCart() - promoAmount).toFixed(2)}
                  </Paragraph>
                )}
              </div>
              <br />

              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // height: "600px",
                  }}
                >
                  <Loader
                    type="snipper-default"
                    bgColor={"#878787"}
                    size={30}
                  />
                </div>
              ) : (
                <>
                  {window.localStorage.getItem("country") == "vietnam" ? (
                    <>
                      {token ? (
                        <>
                          <Button
                            label={t("PAY WITH CARD")}
                            color="#fff"
                            bg="#BA2025"
                            br="50px"
                            pd=".6rem 2.5rem"
                            onClick={() => handlePayNowToken("card")}
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            label={t("PAY WITH CARD")}
                            color="#fff"
                            bg="#BA2025"
                            br="50px"
                            pd=".6rem 2.5rem"
                            onClick={() => handlePayNow("card")}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {token ? (
                        <>
                          <Button
                            label={t("PAY WITH CARD")}
                            color="#fff"
                            bg="#BA2025"
                            br="50px"
                            pd=".6rem 2.5rem"
                            onClick={() => handlePayNowToken("card")}
                          />
                          <br />
                          <Button
                            label={t("PAY WITH FPX")}
                            color="#fff"
                            bg="#BA2025"
                            br="50px"
                            pd=".6rem 2.5rem"
                            onClick={() => handlePayNowToken("fpx")}
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            label={t("PAY WITH CARD")}
                            color="#fff"
                            bg="#BA2025"
                            br="50px"
                            pd=".6rem 2.5rem"
                            onClick={() => handlePayNow("card")}
                          />
                          <br />
                          <Button
                            label={t("PAY WITH FPX")}
                            color="#fff"
                            bg="#BA2025"
                            br="50px"
                            pd=".6rem 2.5rem"
                            onClick={() => handlePayNow("fpx")}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Section>
      </Container>
      <Footer />
      {/* ADD NEW ADDRESS */}
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenModal(false)}>
                <Close style={{ color: "#c5c5c5" }} />
              </a>
            </div>

            <br />
            <Paragraph center size="24px" color="#BA2025" bold="700">
              {t("Add New Address")}
            </Paragraph>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <FormInput
                  label={t("Full Name")}
                  placeholder={t("Full Name")}
                  border="1px solid #707070"
                  name="name"
                  onChange={handleChange}
                />
                <FormInput
                  label={t("Address")}
                  placeholder={t("Address")}
                  border="1px solid #707070"
                  name="address"
                  onChange={handleChange}
                />
                <FormInput
                  label={t("State")}
                  placeholder={t("State")}
                  border="1px solid #707070"
                  name="state"
                  onChange={handleChange}
                />
                <FormInput
                  label={t("Country")}
                  placeholder={t("Country")}
                  border="1px solid #707070"
                  name="country"
                  onChange={handleChange}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChangeCheck}
                      value={checked}
                    />
                  }
                  label={t("Set As Default Address")}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormInput
                  label={t("Phone")}
                  placeholder={t("Phone")}
                  border="1px solid #707070"
                  name="phone"
                  type="number"
                  onChange={handleChange}
                />{" "}
                <FormInput
                  label={t("Town / City")}
                  placeholder={t("Town / City")}
                  border="1px solid #707070"
                  name="city"
                  onChange={handleChange}
                />{" "}
                <FormInput
                  label={t("Zip / Postal Code")}
                  placeholder={t("Zip / Postal Code")}
                  border="1px solid #707070"
                  name="postcode"
                  type="number"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            <br />
            <Button
              label={t("SUBMIT")}
              color="#fff"
              bg="#BA2025"
              br="6px"
              onClick={() => handleAddAddress()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
