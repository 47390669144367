import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";

export const NavLinking = styled.a`
  position: relative;
  width: 100%;
  color: #1e1e1e;
  text-align: center;
  display: block;
  // padding: 0.4rem 0;

  .MuiMenuItem-root {
    display: block;
  }
  .MuiListItem-button:hover {
    background-color: #7f222c !important;
  }
`;

const config = {
  theBGRED: {
    background: "#BA2025",
    padding: "1.5rem 0",
  },

  theHead: {
    background: "#BA2025",
  },
};

export default makeStyles(config);
