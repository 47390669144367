import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../language/en.json";
import translationVN from "../language/vn.json";
import translationMY from "../language/my.json";
import translationCN from "../language/cn.json";

const resources = {
  en: {
    translation: translationEN,
  },
  vn: {
    translation: translationVN,
  },
  my: {
    translation: translationMY,
  },
  cn: {
    translation: translationCN,
  }
};

const langKey = 'lang';

const savedLanguage = localStorage.getItem(langKey);

i18n.use(initReactI18next).init({
  resources,
  lng: savedLanguage || "my",
  fallbackLng: "my",
  interpolation: {
    escapeValue: false,
  },
});

function setLanguage(lang) {
 if(localStorage.getItem("lang")){
  
 }else{
  i18n.changeLanguage(lang);
  localStorage.setItem(langKey, lang);
 }
}

export { setLanguage };

export default i18n;
