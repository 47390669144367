import React, { useContext } from "react";
import { CartContext } from "../context/CartContext";

const Storage = (cartItems) => {
  localStorage.setItem(
    "cart",
    JSON.stringify(cartItems.length > 0 ? cartItems : [])
  );
};

const useCart = () => {
  const [cart, setCart] = useContext(CartContext);

  function getCart() {
    return cart;
  }

  function clearCart() {
    setCart([]);
    Storage([]);
  }

  function setCartFunction(cart = []) {
    // alert(JSON.stringify(cart))
    setCart(cart);
  }

  function addProduct(item) {
    console.log(item);
    if (cart.length == 0) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }
    var newItem = { ...item };

    newItem["index"] = cartTemp.length;
    cartTemp.push(newItem);

    setCart(cartTemp);
    Storage(cartTemp);
  }

  function getTotalCart() {
    var total_amount = 0;

    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }

    for (var i = 0; i < cartTemp.length; i++) {
      var arr = cartTemp[i].price;
      // console.log("pricee", cartTemp[i].price)
      total_amount = parseFloat(total_amount) + parseFloat(arr);
    }
    return total_amount;
  }

  function removeProduct(index) {
    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }
    cartTemp.splice(index, 1);

    for (var i = 0; i < cartTemp.length; i++) {
      cartTemp[i]["index"] = i;
    }

    setCart(cartTemp);
    Storage(cartTemp);
  }

  return {
    addProduct,
    clearCart,
    getTotalCart,
    removeProduct,
    getCart,
    setCartFunction,
  };
};

export default useCart;
