import { makeStyles } from "@mui/styles";
import styled from "styled-components";

export const config = {
  root: {},
};

export const CardContainer = styled.div`
  position: relative;
  border-radius: 10px;
  /* box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 8%); */
  box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  display: block;
  cursor: pointer;
  background: #fff;
  /* border: 1px solid #eee; */
`;

export default makeStyles(config);
