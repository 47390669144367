import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import useStyles from "./styles";

import { Box, Container, Grid } from "@mui/material";
import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";

// IMAGE
import side from "../../assets/images/side.png";
import prod001 from "../../assets/images/prod001.png";
import bg001 from "../../assets/images/bg001.png";

import prodimg001 from "../../assets/images/prodimg001.png";
import prodimg002 from "../../assets/images/prodimg002.png";
import prod002 from "../../assets/images/prod002.png";
import prod003 from "../../assets/images/prod003.png";
import prod004 from "../../assets/images/prod004.png";

import f001 from "../../assets/images/f001.png";
import f002 from "../../assets/images/f002.png";
import f003 from "../../assets/images/f003.png";
import f004 from "../../assets/images/f004.png";
import f005 from "../../assets/images/f005.png";
import f006 from "../../assets/images/f006.png";

import check from "../../assets/images/check.png";

import i001 from "../../assets/images/icon001.png";
import i002 from "../../assets/images/icon002.png";
import i003 from "../../assets/images/icon003.png";
import i004 from "../../assets/images/icon004.png";
import i005 from "../../assets/images/icon005.png";

import n001 from "../../assets/images/n001.png";
import n002 from "../../assets/images/n002.png";
import n003 from "../../assets/images/n003.png";
import n004 from "../../assets/images/n004.png";
import n005 from "../../assets/images/n005.png";
import n006 from "../../assets/images/n006.png";

import wechatscan from "../../assets/images/wechatscan.png";
import telegramscan from "../../assets/images/telegramscan.png";
import zaloscan from "../../assets/images/zaloscan.png";
import whatsapp from "../../assets/images/whatsapp.png";

import iconn from "../../assets/images/iconn.png";

import avatar from "../../assets/images/avatar.png";

import wave from "../../assets/images/wave.png";

import shape001 from "../../assets/images/shape001.png";
import shape002 from "../../assets/images/shape002.png";
import shape003 from "../../assets/images/shape003.png";
import shape004 from "../../assets/images/shape004.png";

import shadow from "../../assets/images/shadow.png";

import vncontact001 from "../../assets/images/vncontact001.png";
import vncontact002 from "../../assets/images/vncontact002.png";
import vncontact003 from "../../assets/images/vncontact003.jpeg";
import vncontact004 from "../../assets/images/vncontact004.jpg";

import banner001 from "../../assets/images/banner001.png";

import rec from "../../assets/images/rec.png";
import Slider from "react-slick";
import Footer from "../../components/Footer/Footer";
import { get_product } from "../../API/API";
import { useNavigate } from "react-router-dom";
import { CheckCircle } from "@mui/icons-material";
import useCart from "../../hooks/useCart";
import { Trans, useTranslation } from "react-i18next";

export default function Home() {
  const classes = useStyles();
  //   let { Home } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Home]);

  const navigate = useNavigate();

  // CHNAGE LANGUAGE
  const { t, i18n } = useTranslation();

  const settings = {
    // className: "center",
    // centerMode: true,
    // infinite: true,
    // centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    arrows: false,
    autoplay: true,

    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [prodList, setProdList] = React.useState([]);
  const [prodListVN, setProdListVN] = React.useState([]);

  const getProduct = () => {
    get_product({
      country: "malaysia",
      currency: "MYR",
    }).then((json) => {
      setProdList(json.data);
      setProdListVN(json.data[0]);
    });
  };

  React.useEffect(() => {
    getProduct();
  }, []);

  const { addProduct, clearCart } = useCart();

  const handleBuyNow = (item) => {
    // let bundleList = {
    //   name: prodList.name,
    //   price: prodList.price,
    //   quantity: count,
    //   images: prodList.image,
    // };
    // // addProduct(bundleList);
    // let array = [];
    // array.push(bundleList);
    // window.localStorage.setItem("bundleList", JSON.stringify(array));

    let bundleList = {
      name: item.name,
      price: item.price,
      quantity: 1,
      images: item.image,
      product_id: item.product_id,
    };
    addProduct(bundleList);
    let array = [];
    array.push(bundleList);
    window.localStorage.setItem("bundleList", JSON.stringify(array));
    navigate("/my/delivery");
  };

  const handleBuyNowVN = () => {
    let bundleList = {
      name: prodListVN.name,
      price: prodListVN.price,
      quantity: 1,
      images: prodListVN.image,
      product_id: prodListVN.product_id,
    };
    addProduct(bundleList);
    let array = [];
    array.push(bundleList);
    window.localStorage.setItem("bundleList", JSON.stringify(array));
    navigate("/my/delivery");
  };

  return (
    <React.Fragment>
      {/* <div className={classes.theSide}>
        <img src={side} />
      </div> */}
      <div className={classes._theWave}>
        <img src={wave} />
      </div>
      <Navbar />
      <Box sx={{ background: "#191720" }}>
        <Container maxWidth="xl">
          <Section pd="4rem 0">
            <Box sx={{ position: "absolute", right: "0", top: "-25px" }}>
              <img src={shape001} />
            </Box>
            <Grid container spacing={3} className={classes.theBox}>
              <Grid item xs={12} sm={12} md={6} className={classes.theR}>
                <Box sx={{ marginTop: { sm: "150px", md: 0 } }}>
                  <Paragraph color="#FFF" margin="0 0 .4rem 0">
                    {t("Premium Health Product Brand")}
                  </Paragraph>
                  <Paragraph
                    color={"#FFF"}
                    bold="700"
                    size="54px"
                    margin="0 0 .8rem 0"
                  >
                    {t("Alitron Biotech Nutrition")}
                    {/* <br />
                    Nutrition */}
                  </Paragraph>
                  <Paragraph color={"#FFF"} margin="0 0 2rem 0">
                    {t("Protect The Strong And Powerful Talent Of Men!")}
                  </Paragraph>
                  <br />
                  <div className={classes.theFlex}>
                    <a>
                      <Button
                        onClick={() => {
                          navigate("/my/bundle");
                        }}
                        // onClick={() => {
                        //   alert("Please contact us to purchase");
                        // }}
                        // onClick={() => handleBuyNow()}
                        label={t("Buy Now")}
                        color="#fff"
                        bg="#BA2025"
                        pd=".6rem 2.5rem"
                        br="50px"
                        style={{ marginRight: 18, marginBottom: "1rem" }}
                      />
                    </a>
                    <a>
                      <Button
                        onClick={() => {
                          navigate("/my/product");
                        }}
                        label={t("Learn More")}
                        bg="transparent"
                        color="#FFF"
                        box="1px solid #FFF"
                        bold="600"
                        br="50px"
                        pd=".6rem 2.5rem"
                        style={{ marginRight: 18, marginBottom: "1rem" }}
                      />
                    </a>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.theProd}>
                  <img src={prod001} />
                </div>
              </Grid>
            </Grid>
            <Box sx={{ position: "absolute", left: "10px", bottom: "-110px" }}>
              <img src={shape002} />
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: "-200px",
                right: { xs: "0", md: "60px" },
                display: { xs: "none", sm: "block" },
              }}
            >
              <img src={shape003} />
            </Box>
          </Section>
          <br />
          <br />
          <br />
          <br />
        </Container>
      </Box>
      <Box
        sx={{
          background: "rgb(236,69,71)",
          background:
            "linear-gradient(90deg, rgba(236,69,71,1) 0%, rgba(249,188,69,1) 100%)",
        }}
      >
        <Section pd="2rem 0">
          <Container maxWidth="lg">
            <Paragraph color="#FFF" size="32px" bold="600" margin="0 0 .4rem">
              {t("Trusted over 3500+ Clients")}
            </Paragraph>
            <Paragraph color="#FFF" bold="600" size="32px">
              {t("Worldwide Since 2020")}
            </Paragraph>
          </Container>
        </Section>
      </Box>

      <Section pd="4rem 0">
        <Container>
          <Paragraph
            color="#2a2835"
            size="50px"
            bold="700"
            margin="0 0 2rem 0"
            center
          >
            {t("How It Works?")}
          </Paragraph>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <div className={classes._theIcon}>
                  <img src={i001} />
                </div>
                <div style={{ width: "80%" }}>
                  <Paragraph
                    color="#2a2835"
                    bold="600"
                    size="22px"
                    margin="0 0 .6rem 0"
                  >
                    {t("Enchance the body function")}
                  </Paragraph>
                  <Paragraph color="#2a2835" bold="500">
                    {t(
                      "Anti aging & resistance to free radicals, increase bone density & reduce joint pain"
                    )}
                  </Paragraph>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <div className={classes._theIcon}>
                  <img src={i004} />
                </div>
                <div style={{ width: "80%" }}>
                  <Paragraph
                    color="#2a2835"
                    bold="600"
                    size="22px"
                    margin="0 0 .6rem 0"
                  >
                    {t("Men's health")}
                  </Paragraph>
                  <Paragraph color="#2a2835" bold="500">
                    {t("Improve male prostate problems & sexual ability")}
                  </Paragraph>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <div className={classes._theIcon}>
                  <img src={i002} />
                </div>
                <div style={{ width: "80%" }}>
                  <Paragraph
                    color="#2a2835"
                    bold="600"
                    size="22px"
                    margin="0 0 .6rem 0"
                  >
                    {t("Body Metabolism")}
                  </Paragraph>
                  <Paragraph color="#2a2835" bold="500">
                    {t(
                      "Promote body metabolism, blood circulation, enhance the spirit & physical strength"
                    )}
                  </Paragraph>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <div className={classes._theIcon}>
                  <img src={i005} />
                </div>
                <div style={{ width: "80%" }}>
                  <Paragraph
                    color="#2a2835"
                    bold="600"
                    size="22px"
                    margin="0 0 .6rem 0"
                  >
                    {t("Women health")}
                  </Paragraph>
                  <Paragraph color="#2a2835" bold="500">
                    {t(
                      "Women can improve a variety of women's issues: relieve symptoms of menopause, frigidity, reduce the risk of breast cancer"
                    )}
                  </Paragraph>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <div className={classes._theIcon}>
                  <img src={i003} />
                </div>
                <div style={{ width: "80%" }}>
                  <Paragraph
                    color="#2a2835"
                    bold="600"
                    size="22px"
                    margin="0 0 .6rem 0"
                  >
                    {t("Blood fat & Bone health")}
                  </Paragraph>
                  <Paragraph color="#2a2835" bold="500">
                    {t(
                      "Reduce blood fat and three high risk, improve osteoporosis"
                    )}
                  </Paragraph>
                </div>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              position: "absolute",
              right: { sm: "100px", md: "100px", lg: "180px", xl: "25%" },
              bottom: {
                xs: "165px",
                sm: "100px",
                md: "240px",
                lg: "160px",
                xl: "180px",
              },
              width: { xs: "90%", sm: "70%", md: "40%", lg: "42%", xl: "25%" },
            }}
          >
            <img src={prod003} className={classes._theProdFloat} />
          </Box>
        </Container>
        <Box className={classes._theShadow}>
          <img src={shadow} />
        </Box>
      </Section>

      <Section pd="4rem 0">
        <Container>
          <Paragraph
            color="#2a2835"
            size="50px"
            bold="700"
            margin="0 0 2rem 0"
            center
          >
            {t("Choose Your Bundle")}
          </Paragraph>
          <Grid container spacing={3}>
            {prodList.map((item, index) => (
              <Grid item xs={12} sm={6} md={6}>
                <Box
                  className={classes._theCard}
                  sx={{
                    background: index % 2 === 0 ? "#F8F8F8" : "#FFC700",
                  }}
                >
                  <div className={classes.theProdIMG}>
                    <img src={item.image} />
                  </div>
                  <Paragraph
                    color="#2a2835"
                    size="39px"
                    bold="500"
                    margin="0 0 .4rem 0"
                  >
                    {item.name}
                  </Paragraph>
                  <Paragraph margin="0 0 1rem 0" size="16px">
                    {item.description}
                  </Paragraph>
                  <Box
                    sx={{
                      display: { xs: "block", lg: "flex" },
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {item.country == "vietnam" ? (
                      <Paragraph
                        className={classes._theMb}
                        color={index % 2 === 0 ? "#9c831f" : "#2a2835"}
                        bold="600"
                        size="25px"
                        center
                      >
                        USD {item.price}
                      </Paragraph>
                    ) : (
                      <Paragraph
                        className={classes._theMb}
                        color={index % 2 === 0 ? "#9c831f" : "#2a2835"}
                        bold="600"
                        size="25px"
                        center
                      >
                        RM {item.price}
                      </Paragraph>
                    )}
                    <Button
                      className={classes._theMb}
                      label={t("BUY NOW")}
                      color="#fff"
                      bg="#BA2025"
                      pd=".6rem 2.5rem"
                      br="50px"
                      // href="/bundle"
                      onClick={() => handleBuyNow(item)}
                      // onClick={() => {
                      //   alert("Please contact us to purchase");
                      // }}
                    />
                    <Button
                      className={classes._theMb}
                      label={t("GET DETAIL")}
                      color="#BA2025"
                      box="1px solid #BA2025"
                      bold="600"
                      pd=".6rem 2.5rem"
                      br="50px"
                      href="/product"
                    />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
          {/* {window.localStorage.getItem("country") == "VN" ? (
            <Grid container spacing={3} sx={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={6} md={6}>
                <Box
                  className={classes._theCard}
                  sx={{
                    background: "#F8F8F8",
                  }}
                >
                  <div className={classes.theProdIMG}>
                    <img src={prodListVN.image} />
                  </div>
                  <Paragraph
                    color="#2a2835"
                    size="39px"
                    bold="500"
                    margin="0 0 .4rem 0"
                  >
                    {prodListVN.name}
                  </Paragraph>
                  <Paragraph margin="0 0 1rem 0" size="16px">
                    {prodListVN.description}
                  </Paragraph>
                  <Box
                    sx={{
                      display: { xs: "block", lg: "flex" },
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Paragraph
                      className={classes._theMb}
                      color={"#9c831f"}
                      bold="600"
                      size="25px"
                      center
                    >
                      USD {prodListVN.price}
                    </Paragraph>
                    <Button
                      className={classes._theMb}
                      label={t("BUY NOW")}
                      color="#fff"
                      bg="#BA2025"
                      pd=".6rem 2rem"
                      br="50px"
                      // href="/bundle"
                      onClick={() => handleBuyNowVN()}
                      // onClick={() => {
                      //   alert("Please contact us to purchase");
                      // }}
                    />
                    <Button
                      className={classes._theMb}
                      label={t("GET DETAIL")}
                      color="#BA2025"
                      box="1px solid #BA2025"
                      bold="600"
                      pd=".6rem 2rem"
                      br="50px"
                      href="/product"
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              {prodList.map((item, index) => (
                <Grid item xs={12} sm={6} md={6}>
                  <Box
                    className={classes._theCard}
                    sx={{
                      background: index % 2 === 0 ? "#F8F8F8" : "#FFC700",
                    }}
                  >
                    <div className={classes.theProdIMG}>
                      <img src={item.image} />
                    </div>
                    <Paragraph
                      color="#2a2835"
                      size="39px"
                      bold="500"
                      margin="0 0 .4rem 0"
                    >
                      {item.name}
                    </Paragraph>
                    <Paragraph margin="0 0 1rem 0" size="16px">
                      {item.description}
                    </Paragraph>
                    <Box
                      sx={{
                        display: { xs: "block", lg: "flex" },
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                       {item.country== "malaysia" ? (
                      <Paragraph
                        className={classes._theMb}
                        color={index % 2 === 0 ? "#9c831f" : "#2a2835"}
                        bold="600"
                        size="25px"
                        center
                      >
                        USD {item.price}
                      </Paragraph>
                    ) : (
                      <Paragraph
                        className={classes._theMb}
                        color={index % 2 === 0 ? "#9c831f" : "#2a2835"}
                        bold="600"
                        size="25px"
                        center
                      >
                        RM {item.price}
                      </Paragraph>
                    )}
                      <Button
                        className={classes._theMb}
                        label={t("BUY NOW")}
                        color="#fff"
                        bg="#BA2025"
                        pd=".6rem 2.5rem"
                        br="50px"
                        // href="/bundle"
                        onClick={() => handleBuyNow(item)}
                        // onClick={() => {
                        //   alert("Please contact us to purchase");
                        // }}
                      />
                      <Button
                        className={classes._theMb}
                        label={t("GET DETAIL")}
                        color="#BA2025"
                        box="1px solid #BA2025"
                        bold="600"
                        pd=".6rem 2.5rem"
                        br="50px"
                        href="/product"
                      />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )} */}
        </Container>
      </Section>

      <div className={classes.theBG2}>
        <Container>
          <Section pd="4rem 1rem">
            <Paragraph
              color={"#2a2835"}
              size="50px"
              bold="700"
              margin="0 0 2rem 0"
              center
            >
              {t("Top Tips")}
            </Paragraph>

            <Box sx={{ display: "grid", justifyContent: "center" }}>
              <div style={{ display: "flex" }}>
                <CheckCircle
                  sx={{
                    color: "#3cdb7f",
                    fontSize: "2.5rem",
                    marginRight: "12px",
                  }}
                />
                <Paragraph size="18px" style={{ lineHeight: 2 }}>
                  {t("Hydrate with warm water for enchanced results")}
                </Paragraph>
              </div>
              <br />
              <div style={{ display: "flex" }}>
                <CheckCircle
                  sx={{
                    color: "#3cdb7f",
                    fontSize: "2.5rem",
                    marginRight: "12px",
                  }}
                />
                <Paragraph size="18px" style={{ lineHeight: 2 }}>
                  {t("Combine with caffeinated beverage for better taste")}
                </Paragraph>
              </div>
            </Box>
          </Section>
          <br />
        </Container>
      </div>

      <Section pd="4rem 0">
        <Container>
          <Paragraph
            color="#2a2835"
            size="44px"
            bold="700"
            center
            margin="0 0 2rem 0"
          >
            {t("Main Ingredient")}
          </Paragraph>
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes._theCardIn}>
                  <img src={n001} />
                </div>
                <Box className={classes._theF} sx={{ marginTop: "-74px" }}>
                  <img src={f001} />
                  <div className={classes.theAb}>
                    <Paragraph center>
                      {t(
                        "Anti-cancer, preventing gene mutation and DNA damage"
                      )}
                    </Paragraph>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes._theCardIn}>
                  <img src={n003} />
                </div>
                <Box className={classes._theF} sx={{ marginTop: "-60px" }}>
                  <img src={f002} />
                  <div className={classes.theAb}>
                    <Paragraph center>
                      {t(
                        "Improved vision & eye fatigue, antiaging, anticancer, healthier heart"
                      )}
                    </Paragraph>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes._theCardIn2}>
                  <img src={n005} />
                </div>
                <Box className={classes._theF} sx={{ marginTop: "-65px" }}>
                  <img src={f003} />
                  <div className={classes.theAb}>
                    <Paragraph center>
                      {t("Supports the body's immune system to produce energy")}
                    </Paragraph>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes._theCardIn3}>
                  <img src={n006} />
                </div>
                <Box className={classes._theF} sx={{ marginTop: "-74px" }}>
                  <img src={f004} />
                  <div className={classes.theAb}>
                    <Paragraph center>
                      {t(
                        "Increase stamina, energy & treat excessive tiredness, promote hormonal balance, treat menopause symptom"
                      )}
                    </Paragraph>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes._theCardIn3}>
                  <img src={n002} />
                </div>
                <Box className={classes._theF} sx={{ marginTop: "-65px" }}>
                  <img src={f005} />
                  <div className={classes.theAb}>
                    <Paragraph center>
                      {t(
                        "Maintains cholesterol levels, improves sleep quality, reduces fatigue"
                      )}
                    </Paragraph>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes._theCardIn3}>
                  <img src={n004} />
                </div>
                <Box className={classes._theF} sx={{ marginTop: "-68px" }}>
                  <img src={f006} />
                  <div className={classes.theAb}>
                    <Paragraph center>
                      {t(
                        "Anti-cancer, masculinity & sexual activity, spermatogenesis cycle & fertility"
                      )}
                    </Paragraph>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Section>

      <Section pd="4rem 0">
        <Container>
          <Paragraph
            color="#2a2835"
            size="44px"
            bold="700"
            margin="0 0 2rem 0"
            center
          >
            <Trans
              i18nKey="Deer"
              components={{ span: <span style={{ color: "#BA2025" }}></span> }}
            />
            {/* Khasiat <span style={{ color: "#BA2025" }}>Placenta Rusa</span> */}
          </Paragraph>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "2.5rem",
                }}
              >
                <div className={classes._theNum}>1</div>
                <Box className={classes._theDetail}>
                  <Paragraph color="#2a2835" bold="500" size="18px">
                    {t(
                      "Tongkat Ali boleh mengimbangi membesab hormon manusia, menggalakkan pengawalsediaan sistem endokrin badan"
                    )}
                  </Paragraph>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "2.5rem",
                }}
              >
                <div className={classes._theNum}>2</div>
                <Box className={classes._theDetail}>
                  <Paragraph color="#2a2835" bold="500" size="18px">
                    {t(
                      "Menggalakkan pembesaran saluran darah, meningkatkan peredaran darah"
                    )}
                  </Paragraph>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "2.5rem",
                }}
              >
                <div className={classes._theNum}>3</div>
                <Box className={classes._theDetail}>
                  <Paragraph color="#2a2835" bold="500" size="18px">
                    {t(
                      "Rusa Placenta: meningkatkan kekuatan fizikal, memperbaiki keadaan fikiran"
                    )}
                  </Paragraph>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "2.5rem",
                }}
              >
                <div className={classes._theNum}>4</div>
                <Box className={classes._theDetail}>
                  <Paragraph color="#2a2835" bold="500" size="18px">
                    {t("Memperbaiki tidur")}
                  </Paragraph>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "2.5rem",
                }}
              >
                <div className={classes._theNum}>5</div>
                <Box className={classes._theDetail}>
                  <Paragraph color="#2a2835" bold="500" size="18px">
                    {t(
                      "Maca mengandungi asid sulfurik mempunyai anti-epilepsi, mengawal kadar jantung gangguan dan kesan lain"
                    )}
                  </Paragraph>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "2.5rem",
                }}
              >
                <div className={classes._theNum}>6</div>
                <Box className={classes._theDetail}>
                  <Paragraph color="#2a2835" bold="500" size="18px">
                    {t("Keseimbangan pigmen kulit manusia, kesan kecantikan")}
                  </Paragraph>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "2.5rem",
                }}
              >
                <div className={classes._theNum}>7</div>
                <Box className={classes._theDetail}>
                  <Paragraph color="#2a2835" bold="500" size="18px">
                    {t(
                      "Kumpulan Vitamin B boleh meningkatkan fungsi imun badan"
                    )}
                  </Paragraph>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "2.5rem",
                }}
              >
                <div className={classes._theNum}>8</div>
                <Box className={classes._theDetail}>
                  <Paragraph color="#2a2835" bold="500" size="18px">
                    {t("Membantu rawatan rematik")}
                  </Paragraph>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              // float: "right",
              marginTop: { xs: "0", md: "-260px" },
              display: "flex",
              justifyContent: { xs: "center", md: "flex-end" },
            }}
          >
            <img src={shape004} />
          </Box>
        </Container>
        <Box className={classes._theShadow2}>
          <img src={shadow} />
        </Box>
      </Section>

      <div className={classes.theBG3}>
        <Container>
          <Section pd="4rem 1rem">
            <div>
              <Paragraph
                color={"#fff"}
                size="44px"
                bold="700"
                margin="0 0 2rem 0"
                center
              >
                {t("Video Testimony")}
              </Paragraph>

              <div
                // className="video"
                style={{
                  position: "relative",
                  paddingBottom: "56.25%" /* 16:9 */,
                  paddingTop: 25,
                  height: 0,
                }}
              >
                <iframe
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  src="https://www.youtube.com/embed/QxJD-Cb_5uc"
                  frameBorder="0"
                />
              </div>
            </div>
          </Section>
          <br />
        </Container>
      </div>

      <Box sx={{ background: "#000" }}>
        <Section pd="4rem 0">
          <Slider className={classes.root} {...settings}>
            <div>
              <Box className={classes._theReview} sx={{ display: "flex" }}>
                <div className={classes._theAvatar}>
                  <img src={n001} />
                </div>
                <div className={classes._theBox}>
                  <div className={classes._theIconn}>
                    <img src={iconn} />
                  </div>
                  <br />
                  <Paragraph color="#939398" bold="500">
                    Pressure from career made me feel exhaused and affected my
                    sexual life as well as performance in work. Alitron chnage
                    my situation, making me more energetic and solving all my
                    issue
                  </Paragraph>
                  <br />
                  <Paragraph color="#2a2835" bold="700">
                    Username
                  </Paragraph>
                  <Paragraph color="#878787" size="14px">
                    lorem ipsum
                  </Paragraph>
                </div>
              </Box>
            </div>
            <div>
              <Box className={classes._theReview} sx={{ display: "flex" }}>
                <div className={classes._theAvatar}>
                  <img src={n002} />
                </div>
                <div className={classes._theBox}>
                  <div className={classes._theIconn}>
                    <img src={iconn} />
                  </div>
                  <br />
                  <Paragraph color="#939398" bold="500">
                    Pressure from career made me feel exhaused and affected my
                    sexual life as well as performance in work. Alitron chnage
                    my situation, making me more energetic and solving all my
                    issue
                  </Paragraph>
                  <br />
                  <Paragraph color="#2a2835" bold="700">
                    Username
                  </Paragraph>
                  <Paragraph color="#878787" size="14px">
                    lorem ipsum
                  </Paragraph>
                </div>
              </Box>
            </div>
            <div>
              <Box className={classes._theReview} sx={{ display: "flex" }}>
                <div className={classes._theAvatar}>
                  <img src={n003} />
                </div>
                <div className={classes._theBox}>
                  <div className={classes._theIconn}>
                    <img src={iconn} />
                  </div>
                  <br />
                  <Paragraph color="#939398" bold="500">
                    Pressure from career made me feel exhaused and affected my
                    sexual life as well as performance in work. Alitron chnage
                    my situation, making me more energetic and solving all my
                    issue
                  </Paragraph>
                  <br />
                  <Paragraph color="#2a2835" bold="700">
                    Username
                  </Paragraph>
                  <Paragraph color="#878787" size="14px">
                    lorem ipsum
                  </Paragraph>
                </div>
              </Box>
            </div>
            <div>
              <Box className={classes._theReview} sx={{ display: "flex" }}>
                <div className={classes._theAvatar}>
                  <img src={n004} />
                </div>
                <div className={classes._theBox}>
                  <div className={classes._theIconn}>
                    <img src={iconn} />
                  </div>
                  <br />
                  <Paragraph color="#939398" bold="500">
                    Pressure from career made me feel exhaused and affected my
                    sexual life as well as performance in work. Alitron chnage
                    my situation, making me more energetic and solving all my
                    issue
                  </Paragraph>
                  <br />
                  <Paragraph color="#2a2835" bold="700">
                    Username
                  </Paragraph>
                  <Paragraph color="#878787" size="14px">
                    lorem ipsum
                  </Paragraph>
                </div>
              </Box>
            </div>
          </Slider>
        </Section>
      </Box>

      <Container>
        <Section pd="4rem 0">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <div className={classes._theIMG}>
                <img src={prod004} />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Paragraph
                color="#BA2125"
                bold="700"
                size="20px"
                margin="0 0 1rem 0"
              >
                {t("Alitron Biotech Nutrition")}
              </Paragraph>
              <Paragraph
                color="#2C2E3F"
                bold="700"
                size="54px"
                margin="0 0 2rem 0"
              >
                {t("Alitron Solve Our Prolems !")}
              </Paragraph>
              <Box>
                <Paragraph bold="700" margin="0 0 .4rem 0">
                  {t("Enchance the body function")}
                </Paragraph>
                <Paragraph margin="0 0 1.5rem 0">
                  {t(
                    "Anti aging & resistance to free radicals, increase bone density & reduce joint pain"
                  )}
                </Paragraph>
                <Paragraph bold="700" margin="0 0 .4rem 0">
                  {t("Body metabolism")}
                </Paragraph>
                <Paragraph margin="0 0 1.5rem 0">
                  {t(
                    "Promote body metabolism, blood circulation, enhance the spirit and physical strength"
                  )}
                </Paragraph>

                <Paragraph bold="700" margin="0 0 .4rem 0">
                  {t("Men's & Women's health")}
                </Paragraph>
                <Paragraph margin="0 0 1.5rem 0">
                  {t(
                    "Improve male prostate problems and sexual ability. Relieve symptoms of menopause, frigidity, reduce the risk of breast cancer"
                  )}
                </Paragraph>

                <Paragraph bold="700" margin="0 0 .4rem 0">
                  {t("6 precious ingredients")}
                </Paragraph>
                <Paragraph margin="0 0 1.5rem 0">
                  {t(
                    "Deer placenta, Maca, Tongkat Ali, Acai berry, Vitamin B and blueberry"
                  )}
                </Paragraph>
              </Box>
            </Grid>
          </Grid>
        </Section>
      </Container>

      <Container>
        <Section pd="4rem 0">
          <img src={banner001} style={{ width: "100%" }} />
        </Section>
      </Container>

      <Container>
        <Section pd="4rem 1rem">
          <Paragraph
            color="#2a2835"
            bold="700"
            size="50px"
            margin="0 0 2rem 0"
            center
          >
            {t("Contact Us")}
          </Paragraph>

          {window.localStorage.getItem("country") == "vietnam" ? (
            <Grid
              container
              spacing={3}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <div className={classes.theScan}>
                  <img src={vncontact001} />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className={classes.theScan}>
                  <img src={vncontact002} />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className={classes.theScan}>
                  <img src={vncontact003} />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className={classes.theScan}>
                  <img src={vncontact004} />
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={3}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <div className={classes.theScan}>
                  <img src={wechatscan} />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className={classes.theScan}>
                  <img src={telegramscan} />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className={classes.theScan}>
                  <img src={zaloscan} />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className={classes.theScan}>
                  <img src={whatsapp} />
                </div>
              </Grid>
            </Grid>
          )}
        </Section>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
