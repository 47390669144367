import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import useStyles from "./styles";

import {
  Box,
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";

// IMAGE
import prod001 from "../../assets/images/prod001.png";
import prod004 from "../../assets/images/prod004.png";

import wechatscan from "../../assets/images/wechatscan.png";
import telegramscan from "../../assets/images/telegramscan.png";
import zaloscan from "../../assets/images/zaloscan.png";
import whatsapp from "../../assets/images/whatsapp.png";
import desp001 from "../../assets/images/desp001.png";
import desp002 from "../../assets/images/desp002.png";
import desp003 from "../../assets/images/desp003.png";
import desp004 from "../../assets/images/desp004.png";
import desp005 from "../../assets/images/desp005.png";
import desp006 from "../../assets/images/desp006.png";
import desp007 from "../../assets/images/desp007.png";

import vncontact001 from "../../assets/images/vncontact001.png";
import vncontact002 from "../../assets/images/vncontact002.png";
import vncontact003 from "../../assets/images/vncontact003.jpeg";
import vncontact004 from "../../assets/images/vncontact004.jpg";

import banner001 from "../../assets/images/banner001.png";

import fact from "../../assets/images/fact.png";

import Slider from "react-slick";
import Footer from "../../components/Footer/Footer";
import { ArrowDropDown } from "@mui/icons-material";
import { get_product } from "../../API/API";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useCart from "../../hooks/useCart";

export default function Product() {
  const classes = useStyles();
  const navigate = useNavigate();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Product]);

  // CHNAGE LANGUAGE
  const { t, i18n } = useTranslation();

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    className: "center",
    centerMode: true,
    centerPadding: "0px",

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          //   dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  //

  const [prodList, setProdList] = React.useState([]);

  const getProduct = () => {
    get_product({
      country: "malaysia",
      currency: "MYR"
    }).then((json) => {
      setProdList(json.data);
    });
  };

  React.useEffect(() => {
    getProduct();
  }, []);

  const { addProduct, clearCart } = useCart();

  const handleBuyNow = (item) => {
    // let bundleList = {
    //   name: prodList.name,
    //   price: prodList.price,
    //   quantity: count,
    //   images: prodList.image,
    // };
    // // addProduct(bundleList);
    // let array = [];
    // array.push(bundleList);
    // window.localStorage.setItem("bundleList", JSON.stringify(array));

    // alert("Please contact us to purchase");
    // return;

    let bundleList = {
      name: item.name,
      price: item.price,
      quantity: 1,
      images: item.image,
      product_id: item.product_id,
    };
    addProduct(bundleList);
    let array = [];
    array.push(bundleList);
    window.localStorage.setItem("bundleList", JSON.stringify(array));
    navigate("/my/delivery");
  };

  return (
    <React.Fragment>
      <Navbar />
      <Container>
        <Section pd="4rem 0">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={classes.theProd}>
                <img src={prod004} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Paragraph color="#2A2835" bold="500" margin="0 0 .4rem 0">
                {t("Premium Health Product Brand")}
              </Paragraph>
              <Paragraph
                color={"#2A2835"}
                bold="700"
                size="48px"
                margin="0 0 .8rem 0"
              >
                {t("Alitron Biotech Nutrition")}
              </Paragraph>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDown style={{ color: "#BA2025" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Paragraph size="16px" bold="600" color="#2A2835">
                    {t("Description")}
                  </Paragraph>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ background: "#F6F6F6", padding: "1rem !important" }}
                >
                  <Paragraph bold="600" margin="0 0 1rem 0" size="14px">
                    {t(
                      "Alitron Biotech Nutrition concerned about you, we provide men skin care products. Alitron by consists of 6 all-natural essences. Protect the strong and powerful talent of men!"
                    )}
                  </Paragraph>
                  <Paragraph bold="600" margin="0 0 1rem 0" size="14px">
                    {t(
                      "Alitron helps anti-aging, anti-cancer, repair/regenerate cells. Prevents vision loss, balances human endocrine, improves libido, and is a health product suitable for boys and girls."
                    )}
                  </Paragraph>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDown style={{ color: "#BA2025" }} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Paragraph bold="600" size="16px" color="#2A2835">
                    {t("Ingredient")}
                  </Paragraph>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ background: "#F6F6F6", padding: "1rem !important" }}
                >
                  <Paragraph bold="600" margin="0 0 1rem 0" size="14px">
                    {t(
                      "Deer Plancenta 450mg, B Complex, Maca Extract, Plus Tongkat Ali Extract, Acai Berry Extract."
                    )}
                  </Paragraph>
                  <img src={fact} style={{ width: "100%" }} />
                  <br />
                  <br />
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDown style={{ color: "#BA2025" }} />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Paragraph bold="600" size="16px" color="#2A2835">
                    {t("How To Consume")}
                  </Paragraph>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ background: "#F6F6F6", padding: "1rem !important" }}
                >
                  <Paragraph bold="600" margin="0 0 1rem 0" size="14px">
                    {t("Take 1 sachet every 2 days.")} <br />{" "}
                    {t("Drink more water for better result.")}
                  </Paragraph>
                  <Paragraph bold="600" margin="0 0 1rem 0" size="14px">
                    {t(
                      "Store in a cool dry place below 30oc and away from direcrt sunlight Keep out of reach of children."
                    )}
                  </Paragraph>
                  <Paragraph bold="600" margin="0 0 1rem 0" size="14px">
                    {t("Keep out of reach of children.")}
                  </Paragraph>
                </AccordionDetails>
              </Accordion>
              <br />
              <br />
              <br />

              <Button
                href="/my/bundle"
                label={t("SEE BUNDLE")}
                color="#fff"
                bg="#BA2025"
                br="50px"
                pd=".6rem 2.5rem"
                style={{ marginBottom: ".6rem" }}
              />
            </Grid>
          </Grid>
        </Section>
      </Container>

      <Container>
        <Section pd="4rem 0">
          <img src={desp001} style={{ width: "100%" }} />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <img src={desp002} style={{ width: "100%" }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <img src={desp003} style={{ width: "100%" }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <img src={desp004} style={{ width: "100%" }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <img src={desp005} style={{ width: "100%" }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <img src={desp006} style={{ width: "100%" }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <img src={desp007} style={{ width: "100%" }} />
            </Grid>
          </Grid>
        </Section>
      </Container>
      <Section pd="4rem 0">
        <Container>
          <Paragraph
            color="#2a2835"
            size="50px"
            bold="700"
            margin="0 0 2rem 0"
            center
          >
            {t("Choose Your Bundle")}
          </Paragraph>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} sm={6} md={6}>
              <Box className={classes._theCard} sx={{ background: "#f8f8fa" }}>
                <div className={classes.theProdIMG}>
                  <img src={prod001} />
                </div>
                <Paragraph
                  color="#2a2835"
                  size="39px"
                  bold="500"
                  margin="0 0 .4rem 0"
                >
                  1 Pack Alitron
                </Paragraph>
                <Paragraph margin="0 0 1rem 0" size="16px">
                  Include xxx sachets of alitron biotech nutrition
                </Paragraph>
                <Box
                  sx={{
                    display: { xs: "block", lg: "flex" },
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Paragraph
                    className={classes._theMb}
                    color={"#9c831f"}
                    bold="600"
                    size="25px"
                    center
                  >
                    RM 100.00
                  </Paragraph>
                  <Button
                    className={classes._theMb}
                    label={t("BUY NOW")}
                    color="#fff"
                    bg="#BA2025"
                    pd=".6rem 2.5rem"
                    br="50px"
                    href="/bundle"
                  />
                  <Button
                    className={classes._theMb}
                    label={t("GET DETAIL")}
                    color="#BA2025"
                    box="1px solid #BA2025"
                    bold="600"
                    pd=".6rem 2.5rem"
                    br="50px"
                    href="/product"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box className={classes._theCard} sx={{ background: "#ffc700" }}>
                <div className={classes.theProdIMG}>
                  <img src={prod001} />
                </div>
                <Paragraph
                  color="#2a2835"
                  size="39px"
                  bold="500"
                  margin="0 0 .4rem 0"
                >
                  4 Pack Alitron
                </Paragraph>
                <Paragraph margin="0 0 1rem 0" size="16px">
                  Include xxx sachets of alitron biotech nutrition
                </Paragraph>
                <Box
                  sx={{
                    display: { xs: "block", lg: "flex" },
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Paragraph
                    className={classes._theMb}
                    color={"#2a2835"}
                    bold="600"
                    size="25px"
                    center
                  >
                    RM 666.00
                  </Paragraph>
                  <Button
                    className={classes._theMb}
                    label={t("BUY NOW")}
                    color="#fff"
                    bg="#BA2025"
                    pd=".6rem 2.5rem"
                    br="50px"
                    href="/bundle"
                  />
                  <Button
                    className={classes._theMb}
                    label={t("GET DETAIL")}
                    color="#BA2025"
                    box="1px solid #BA2025"
                    bold="600"
                    pd=".6rem 2.5rem"
                    br="50px"
                    href="/product"
                  />
                </Box>
              </Box>
            </Grid> */}
            {prodList.map((item, index) => (
              <Grid item xs={12} sm={6} md={6}>
                <Box
                  className={classes._theCard}
                  sx={{ background: index % 2 === 0 ? "#F8F8F8" : "#FFC700" }}
                >
                  <div className={classes.theProdIMG}>
                    <img src={prod001} />
                  </div>
                  <Paragraph
                    color="#2a2835"
                    size="39px"
                    bold="500"
                    margin="0 0 .4rem 0"
                  >
                    {item.name}
                  </Paragraph>
                  <Paragraph margin="0 0 1rem 0" size="16px">
                    {item.description}
                  </Paragraph>
                  <Box
                    sx={{
                      display: { xs: "block", lg: "flex" },
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {item.country== "vietnam" ? (
                      <Paragraph
                        className={classes._theMb}
                        color={index % 2 === 0 ? "#9c831f" : "#2a2835"}
                        bold="600"
                        size="25px"
                        center
                      >
                        USD {item.price}
                      </Paragraph>
                    ) : (
                      <Paragraph
                        className={classes._theMb}
                        color={index % 2 === 0 ? "#9c831f" : "#2a2835"}
                        bold="600"
                        size="25px"
                        center
                      >
                        RM {item.price}
                      </Paragraph>
                    )}

                    <Button
                      className={classes._theMb}
                      label={t("BUY NOW")}
                      color="#fff"
                      bg="#BA2025"
                      pd=".6rem 2.5rem"
                      br="50px"
                      // href="/bundle"
                      onClick={() => handleBuyNow(item)}
                    />
                    <Button
                      className={classes._theMb}
                      label={t("GET DETAIL")}
                      color="#BA2025"
                      box="1px solid #BA2025"
                      bold="600"
                      pd=".6rem 2.5rem"
                      br="50px"
                      href="/my/product"
                    />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Section>
      <Container>
        <Section pd="4rem 0">
          <img src={banner001} style={{ width: "100%" }} />
        </Section>
      </Container>

      <Container>
        <Section pd="4rem 1rem">
          <Paragraph
            color="#2a2835"
            bold="700"
            size="50px"
            margin="0 0 2rem 0"
            center
          >
            {t("Contact Us")}
          </Paragraph>

          {window.localStorage.getItem("country") == "vietnam" ? (
            <Grid
              container
              spacing={3}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <div className={classes.theScan}>
                  <img src={vncontact001} />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className={classes.theScan}>
                  <img src={vncontact002} />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className={classes.theScan}>
                  <img src={vncontact003} />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className={classes.theScan}>
                  <img src={vncontact004} />
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={3}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <div className={classes.theScan}>
                  <img src={wechatscan} />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className={classes.theScan}>
                  <img src={telegramscan} />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className={classes.theScan}>
                  <img src={zaloscan} />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className={classes.theScan}>
                  <img src={whatsapp} />
                </div>
              </Grid>
            </Grid>
          )}
        </Section>
      </Container>

      <Footer />
    </React.Fragment>
  );
}
