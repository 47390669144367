import React from "react";
import {
  Container,
  Grid,
  Dialog,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles, { NavLinking } from "./styles";
import {
  Person,
  Lock,
  Bookmark,
  Inbox,
  Close,
  Mail,
  Phone,
  MoreVert,
  Edit,
  Add,
  Delete,
} from "@mui/icons-material";
import FormInput from "../../components/FormInput/FormInput";
import Button from "../../components/Button/Button";
import logo from "../../assets/images/logo.png";
import iconnn from "../../assets/images/iconnn.png";
import {
  address_book_detail,
  add_address_book,
  change_password,
  delete_address_book,
  edit_address_book,
  edit_profile,
  get_address_book,
  get_order_history,
  get_profile,
} from "../../API/API";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const classes = useStyles();

  const navigate = useNavigate();

  const [tab, setTab] = React.useState(0);

  const handleChangeTab = (txt) => {
    setTab(txt);
  };

  // CHNAGE LANGUAGE
  const { t, i18n } = useTranslation();

  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const [openEditModal, setOpenEditModal] = React.useState(false);

  const handleOpenEditModal = (user_address_id) => {
    setOpenEditModal(true, user_address_id);
    setAddressBookID(user_address_id);
  };

  // EDIT INFORMATION

  const [openEditInfoModal, setOpenEditInfoModal] = React.useState(false);

  const handleOpenEditInfoModal = () => {
    setOpenEditInfoModal(true);
  };

  // CHANGE PASSWORD

  const [openChangePasswordModal, setOpenChangePasswordModal] =
    React.useState(false);

  const handleOpenChangePasswordModal = () => {
    setOpenChangePasswordModal(true);
  };

  //

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const open2 = Boolean(anchorEl2);

  const handleMenu2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const [checked, setChecked] = React.useState(true);

  const [defaultAddress, setDefaultAddress] = React.useState(1);

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);

    // console.log(event.target.checked);

    if (event.target.checked == true) {
      setDefaultAddress(1);
    }

    if (event.target.checked == false) {
      setDefaultAddress(0);
    }
  };

  // console.log(defaultAddress);

  //

  const [addressData, setAddressData] = React.useState({
    name: "",
    address: "",
    state: "",
    country: "",
    phone: "",
    city: "",
    postcode: "",
  });

  const [passwordData, setPasswordData] = React.useState({
    old_password: "",
    password: "",
    password2: "",
  });

  const handleChange = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    });
    setAddressData({
      ...addressData,
      [e.target.name]: e.target.value,
    });
    setAddressBookDetail({
      ...addressBookDetail,
      [e.target.name]: e.target.value,
    });
    setPasswordData({
      passwordData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {};

  //

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  const [profile, setProfile] = React.useState({
    email: "",
    name: "",
    phone: "",
  });

  const [orderHis, setOrderHis] = React.useState([]);

  const [addressBook, setAddressBook] = React.useState([]);

  const [addressBookID, setAddressBookID] = React.useState();

  const [addressBookDetail, setAddressBookDetail] = React.useState({
    name: "",
    address: "",
    state: "",
    country: "",
    phone: "",
    city: "",
    postcode: "",
  });

  const getProfile = () => {
    get_profile({ token: token }).then((json) => {
      setProfile(json.data);
    });
  };

  const [clientSecret, setClientSecret] = React.useState("");
  const [paymentID, setPaymentId] = React.useState("");

  const getOrderHistory = () => {
    get_order_history({ token: token }).then((json) => {
      setOrderHis(json.data);
      // setClientSecret(json.data.stripe_client_secret);
      // setPaymentId(json.data.stripe_payment_intent);
    });
  };

  const getAddressBook = () => {
    get_address_book({ token: token }).then((json) => {
      if (json.status) {
        setAddressBook(json.data);
      } else {
        window.localStorage.removeItem("token");
        navigate("/my");
        window.location.reload();
      }
      // setAddressBookID(json.data[0].user_address_id);
    });
  };

  const getAddressBookDetail = () => {
    address_book_detail({ user_address_id: addressBookID }).then((json) => {
      setAddressBookDetail(json.data);
    });
  };

  React.useEffect(() => {
    getProfile();
    getOrderHistory();
    getAddressBook();
    getAddressBookDetail();
  }, [addressBookID]);

  // EDIT PROFILE

  const handleEditProfile = () => {
    if (
      profile.name.length == "" ||
      profile.email.length == "" ||
      profile.phone.length == ""
    ) {
      alert("All Field Required");
    } else {
      edit_profile({
        ...profile,
        token: token,
      }).then((json) => {
        if (json.status) {
          alert(json.message);
        } else {
          alert(json.message);
        }
      });
    }
  };

  // CHANGE PASSWORD

  const handleSavePassword = () => {
    if (
      passwordData.old_password.length == "" ||
      passwordData.password.length == "" ||
      passwordData.password2.length == ""
    ) {
      alert("All Field Required");
    } else {
      change_password({
        ...passwordData,
        token: token,
      }).then((json) => {
        if (json.status) {
          alert(json.message);
        } else {
          alert(json.message);
        }
      });
    }
  };

  // ADD NEW ADDRESS BOOK

  const handleAddAddress = () => {
    if (
      addressData.name.length == "" ||
      addressData.phone.length == "" ||
      addressData.address.length == "" ||
      addressData.city.length == "" ||
      addressData.postcode.length == "" ||
      addressData.state.length == "" ||
      addressData.country.length == ""
    ) {
      alert("All Field Required");
    } else {
      add_address_book({
        ...addressData,
        token: token,
        is_default: defaultAddress,
      }).then((json) => {
        if (json.status) {
          // alert(json.message);
          window.location.reload();
        } else {
          alert(json.message);
        }
      });
    }
  };

  // ADDRESS BOOK DETAIL

  // EDIT ADDRESS BOOK

  const handleEditAddress = () => {
    if (
      addressBookDetail.name.length == "" ||
      addressBookDetail.phone.length == "" ||
      addressBookDetail.address.length == "" ||
      addressBookDetail.city.length == "" ||
      addressBookDetail.postcode.length == "" ||
      addressBookDetail.state.length == "" ||
      addressBookDetail.country.length == ""
    ) {
      alert("All Field Required");
    } else {
      edit_address_book({
        ...addressBookDetail,
        token: token,
        user_address_id: addressBookID,
        is_default: defaultAddress,
      }).then((json) => {
        if (json.status) {
          // alert(json.message);
          window.location.reload();
        } else {
          // alert(json.message);
        }
      });
    }
  };

  // DELETE ADDRESS BOOK

  const handleDeleteAddress = (user_address_id) => {
    delete_address_book({ user_address_id: user_address_id }).then((json) => {
      if (json.status) {
        alert(json.message);
        window.location.reload();
      } else {
        alert(json.message);
      }
    });
  };

  // const [isLoading, setIsLoading] = React.useState(false);

  const handlePayNowCard = (item) => {
    // navigate(
    //   "/payment/" +
    //     "card/" +
    //     item.stripe_payment_intent +
    //     "/" +
    //     item.stripe_client_secret
    // );
    // navigate("/payment/" + "card", {
    //   state: {
    //     secret: item.stripe_client_secret,
    //     payment_id: item.stripe_payment_intent,
    //   },
    // });
    navigate(
      "/my/payment/" +
        "card" +
        "/" +
        item.stripe_client_secret +
        "/" +
        item.stripe_payment_intent
    );
  };

  const handlePayNowFPX = (item) => {
    // navigate(
    //   "/payment/" +
    //     "fpx/" +
    //     item.stripe_payment_intent +
    //     "/" +
    //     item.stripe_client_secret
    // );
    // navigate("/payment/" + "fpx", {
    //   state: {
    //     secret: item.stripe_client_secret,
    //     payment_id: item.stripe_payment_intent,
    //   },
    // });
    navigate(
      "/my/payment/" +
        "fpx" +
        "/" +
        item.stripe_client_secret +
        "/" +
        item.stripe_payment_intent
    );
  };

  return (
    <React.Fragment>
      <Navbar />
      <div style={{ background: "#f2f2f2" }}>
        <Container>
          <Section pd="4rem 0">
            <div className={classes.theCard}>
              <div className={classes.theBelowIcon}>
                <img src={iconnn} />
              </div>
              <div className={classes.thePos}>
                <a onClick={handleMenu}>
                  <MoreVert style={{ color: "#BA2025" }} />
                </a>
              </div>
              <Menu
                id="menu-appbar"
                elevation={0}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                keepMounted
                open={open}
                onClose={handleClose}
              >
                <MenuItem style={{ borderBottom: "1px solid #BA2025" }}>
                  <NavLinking onClick={() => handleOpenEditInfoModal()}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Edit style={{ color: "#BA2025", marginRight: "1rem" }} />
                      <Paragraph color="#BA2025">
                        {t("Edit Information")}
                      </Paragraph>
                    </div>
                  </NavLinking>
                </MenuItem>
                <MenuItem>
                  <NavLinking onClick={() => handleOpenChangePasswordModal()}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Lock style={{ color: "#BA2025", marginRight: "1rem" }} />
                      <Paragraph color="#BA2025">
                        {t("Change Password")}
                      </Paragraph>
                    </div>
                  </NavLinking>
                </MenuItem>
              </Menu>
              <div className={classes.theFlex}>
                <div className={classes.thePIC}>
                  <img src={logo} />
                </div>
                <div>
                  <Paragraph color="#BA2025" size="30px" margin="0 0 1rem 0">
                    {profile.name}
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: ".6rem",
                    }}
                  >
                    <Mail style={{ color: "#BA2025", marginRight: "1rem" }} />
                    <Paragraph>{profile.email}</Paragraph>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Phone style={{ color: "#BA2025", marginRight: "1rem" }} />
                    <Paragraph>{profile.phone}</Paragraph>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <div className={classes.theCard}>
                  <div
                    style={{
                      display: "flex",
                      alignItem: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Paragraph
                      bold="600"
                      color="#BA2025"
                      size="20px"
                      margin="0 0 1rem 0"
                    >
                      {t("ADDRESS BOOK")}
                    </Paragraph>
                    <Add
                      style={{ color: "#BA2025" }}
                      onClick={() => handleOpenModal()}
                    />
                  </div>
                  <Paragraph margin="0 0 1rem 0">
                    {t("Default Address")}
                  </Paragraph>
                  {addressBook.map((item) => (
                    <>
                      {item.is_default == 1 && (
                        <div className={classes.theDefaultCard}>
                          <div className={classes.theFlex_}>
                            <Paragraph color="#FFF" size="18px">
                              {item.name}
                            </Paragraph>
                            {/* <a onClick={handleMenu2}>
                              <MoreVert />
                            </a> */}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                              }}
                            >
                              <Edit
                                style={{ color: "#FFF", marginRight: "1rem" }}
                                onClick={() =>
                                  handleOpenEditModal(item.user_address_id)
                                }
                              />
                              <Delete
                                style={{ color: "#FFF", marginRight: "0" }}
                                onClick={() =>
                                  handleDeleteAddress(item.user_address_id)
                                }
                              />
                            </div>
                          </div>
                          <Paragraph color="#FFF">
                            {item.address}, {item.city}, {item.postcode},{" "}
                            {item.state}, {item.contry}
                          </Paragraph>
                          <Paragraph color="#FFF">{item.phone}</Paragraph>
                        </div>
                      )}
                    </>
                  ))}

                  <Paragraph margin="0 0 1rem 0">
                    {t("Other Address")}
                  </Paragraph>

                  {addressBook.map((item) => (
                    <>
                      {item.is_default == 0 && (
                        <div className={classes.theOtherCard}>
                          <div className={classes.theFlex_}>
                            <Paragraph size="18px">{item.name}</Paragraph>
                            {/* <a onClick={handleMenu2}>
                                <MoreVert />
                              </a> */}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                              }}
                            >
                              <Edit
                                style={{ color: "#000", marginRight: "1rem" }}
                                onClick={() =>
                                  handleOpenEditModal(item.user_address_id)
                                }
                              />
                              <Delete
                                style={{ color: "#000", marginRight: "0" }}
                                onClick={() =>
                                  handleDeleteAddress(item.user_address_id)
                                }
                              />
                            </div>
                          </div>
                          <Paragraph>
                            {item.address}, {item.city}, {item.postcode},{" "}
                            {item.state}, {item.contry}
                          </Paragraph>
                          <Paragraph>{item.phone}</Paragraph>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className={classes.theCard}>
                  <Paragraph
                    bold="600"
                    color="#BA2025"
                    size="20px"
                    margin="0 0 1rem 0"
                  >
                    {t("ORDER HISTORY")}
                  </Paragraph>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("Date Created")}</TableCell>
                          <TableCell>{t("Order ID")}</TableCell>
                          <TableCell>{t("Total Amount")}</TableCell>
                          <TableCell>{t("Status")}</TableCell>
                          <TableCell>{t("Tracking Code")}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orderHis.map((item) => (
                          <TableRow>
                            <TableCell>{item.created_date}</TableCell>
                            <TableCell>{item.orders_id}</TableCell>
                            {window.localStorage.getItem("country") == "vietnam" ? (
                              <TableCell>USD {item.total_amount}</TableCell>
                            ) : (
                              <TableCell>RM {item.total_amount}</TableCell>
                            )}

                            <TableCell>{item.status}</TableCell>
                            <TableCell>{item.tracking_code}</TableCell>
                            {item.is_paid == 0 && (
                              <TableCell>
                                {window.localStorage.getItem("country") ==
                                "vietnam" ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Button
                                      label={t("PAY WITH CARD")}
                                      color="#fff"
                                      bg="#BA2025"
                                      br="4px"
                                      pd=".6rem 1rem"
                                      style={{ marginRight: "1rem" }}
                                      onClick={() => handlePayNowCard(item)}
                                    />
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Button
                                      label={t("PAY WITH CARD")}
                                      color="#fff"
                                      bg="#BA2025"
                                      br="4px"
                                      pd=".6rem 1rem"
                                      style={{ marginRight: "1rem" }}
                                      onClick={() => handlePayNowCard(item)}
                                    />

                                    <Button
                                      label={t("PAY WITH FPX")}
                                      color="#fff"
                                      bg="#BA2025"
                                      br="4px"
                                      pd=".6rem 1rem"
                                      onClick={() => handlePayNowFPX(item)}
                                    />
                                  </Box>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* <div className={classes.theFTable}>
                    <a
                      href="/orderhistory"
                      style={{
                        color: "#BA2025",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      View All Order History
                    </a>
                  </div> */}
                </div>
              </Grid>
            </Grid>
          </Section>
        </Container>
      </div>
      <Footer />

      <Menu
        id="menu-appbar"
        elevation={0}
        anchorEl={anchorEl2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        open={open2}
        onClose={handleClose2}
      >
        <MenuItem style={{ borderBottom: "1px solid #BA2025" }}>
          <NavLinking onClick={() => handleOpenEditModal()}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Edit style={{ color: "#BA2025", marginRight: "1rem" }} />
              <Paragraph color="#BA2025">Edit</Paragraph>
            </div>
          </NavLinking>
        </MenuItem>
        <MenuItem>
          <NavLinking>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Delete style={{ color: "#BA2025", marginRight: "1rem" }} />
              <Paragraph color="#BA2025">Delete</Paragraph>
            </div>
          </NavLinking>
        </MenuItem>
      </Menu>

      {/* ADD NEW ADDRESS */}
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenModal(false)}>
                <Close style={{ color: "#c5c5c5" }} />
              </a>
            </div>

            <br />
            <Paragraph center size="24px" color="#BA2025" bold="700">
              {t("Add New Address")}
            </Paragraph>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <FormInput
                  label={t("Full Name")}
                  placeholder={t("Full Name")}
                  border="1px solid #707070"
                  name="name"
                  onChange={handleChange}
                />
                <FormInput
                  label={t("Address")}
                  placeholder={t("Address")}
                  border="1px solid #707070"
                  name="address"
                  onChange={handleChange}
                />
                <FormInput
                  label={t("State")}
                  placeholder={t("State")}
                  border="1px solid #707070"
                  name="state"
                  onChange={handleChange}
                />
                <FormInput
                  label={t("Country")}
                  placeholder={t("Country")}
                  border="1px solid #707070"
                  name="country"
                  onChange={handleChange}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChangeCheck}
                      value={checked}
                    />
                  }
                  label={t("Set As Default Address")}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormInput
                  label={t("Phone")}
                  placeholder={t("Phone")}
                  border="1px solid #707070"
                  name="phone"
                  type="number"
                  onChange={handleChange}
                />{" "}
                <FormInput
                  label={t("Town / City")}
                  placeholder={t("Town / City")}
                  border="1px solid #707070"
                  name="city"
                  onChange={handleChange}
                />{" "}
                <FormInput
                  label={t("Zip / Postal Code")}
                  placeholder={t("Zip / Postal Code")}
                  border="1px solid #707070"
                  name="postcode"
                  type="number"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            <br />
            <Button
              label={t("SUBMIT")}
              color="#fff"
              bg="#BA2025"
              br="6px"
              onClick={() => handleAddAddress()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
      {/* EDIT ADDRESS */}
      <Dialog
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenEditModal(false)}>
                <Close style={{ color: "#c5c5c5" }} />
              </a>
            </div>

            <br />
            <Paragraph center size="24px" color="#BA2025" bold="700">
              {t("Edit Address")}
            </Paragraph>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <FormInput
                  label={t("Full Name")}
                  placeholder={t("Full Name")}
                  border="1px solid #707070"
                  name="name"
                  onChange={handleChange}
                  value={addressBookDetail.name}
                />
                <FormInput
                  label={t("Address")}
                  placeholder={t("Address")}
                  border="1px solid #707070"
                  name="address"
                  onChange={handleChange}
                  value={addressBookDetail.address}
                />
                <FormInput
                  label={t("State")}
                  placeholder={t("State")}
                  border="1px solid #707070"
                  name="state"
                  onChange={handleChange}
                  value={addressBookDetail.state}
                />
                <FormInput
                  label={t("Country")}
                  placeholder={t("Country")}
                  border="1px solid #707070"
                  name="country"
                  onChange={handleChange}
                  value={addressBookDetail.country}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChangeCheck}
                      value={checked}
                    />
                  }
                  label="Set As Default Address"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormInput
                  label={t("Phone")}
                  placeholder={t("Phone")}
                  border="1px solid #707070"
                  name="phone"
                  onChange={handleChange}
                  type="number"
                  value={addressBookDetail.phone}
                />{" "}
                <FormInput
                  label={t("Town / City")}
                  placeholder={t("Town / City")}
                  border="1px solid #707070"
                  name="city"
                  onChange={handleChange}
                  value={addressBookDetail.city}
                />{" "}
                <FormInput
                  label={t("Zip / Postal Code")}
                  placeholder={t("Zip / Postal Code")}
                  border="1px solid #707070"
                  name="postcode"
                  type="number"
                  onChange={handleChange}
                  value={addressBookDetail.postcode}
                />
              </Grid>
            </Grid>

            <br />
            <Button
              label={t("SUBMIT")}
              color="#fff"
              bg="#BA2025"
              br="6px"
              onClick={() => handleEditAddress()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
      {/* USER INFORMATION */}
      <Dialog
        open={openEditInfoModal}
        onClose={() => setOpenEditInfoModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenEditInfoModal(false)}>
                <Close style={{ color: "#c5c5c5" }} />
              </a>
            </div>

            <br />
            <Paragraph center size="24px" color="#BA2025" bold="700">
              {t("User Information")}
            </Paragraph>

            <FormInput
              label={t("Name")}
              placeholder={t("Name")}
              border="1px solid #707070 "
              name="name"
              onChange={handleChange}
              value={profile.name}
            />
            <FormInput
              label={t("Email Address")}
              placeholder={t("Email Address")}
              border="1px solid #707070 "
              name="email"
              onChange={handleChange}
              value={profile.email}
            />
            <FormInput
              label={t("Contact Number")}
              placeholder={t("Contact Number")}
              border="1px solid #707070 "
              name="phone"
              type="number"
              onChange={handleChange}
              value={profile.phone}
            />

            <br />
            <Button
              label={t("SAVE")}
              color="#fff"
              bg="#BA2025"
              br="6px"
              onClick={() => handleEditProfile()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
      {/* PASSWORD */}
      <Dialog
        open={openChangePasswordModal}
        onClose={() => setOpenChangePasswordModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenChangePasswordModal(false)}>
                <Close style={{ color: "#c5c5c5" }} />
              </a>
            </div>

            <br />
            <Paragraph center size="24px" color="#BA2025" bold="700">
              {t("Change Password")}
            </Paragraph>

            <FormInput
              label={t("Current Password")}
              placeholder={t("Current Password")}
              border="1px solid #707070 "
              type="password"
              name="old_password"
              onChange={handleChange}
            />
            <FormInput
              label={t("New Password")}
              placeholder={t("New Password")}
              border="1px solid #707070 "
              type="password"
              name="password"
              onChange={handleChange}
            />
            <FormInput
              label={t("Re-enter New Password")}
              placeholder={t("Re-enter New Password")}
              border="1px solid #707070 "
              type="password"
              name="password2"
              onChange={handleChange}
            />

            <br />
            <Button
              label={t("SUBMIT")}
              color="#fff"
              bg="#BA2025"
              br="6px"
              onClick={() => handleSavePassword()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
