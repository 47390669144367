import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";

import bg001 from "../../assets/images/bg001.png";
import bg002 from "../../assets/images/bg002.png";
import bgred from "../../assets/images/bgred.png";

export const IncrementAction = styled.div`
  background: #ba2025;
  padding: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: ${(props) => props.margin};
`;

const config = {
  root: {
    position: "relative",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        height: "100%",
      },
      "& > div > div": {
        height: "100%",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: 15,

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#000",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: 10,

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#000",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      bottom: "-25px !important",
      ...md("sm", {
        bottom: "0px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          //   backgroundColor: "#eee",
          border: "1px solid #979797",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 10,
            backgroundColor: "#979797",
          },
        },
      },
    },
  },

  theSide: {
    position: "absolute",
    top: 0,
    right: 0,

    "& img": {
      width: "100%",
    },
  },
  theProd: {
    position: "relative",
    "& img": {
      width: "100%",
    },
  },

  theBox: {
    flexDirection: "column-reverse !important",

    ...md("sm", {
      flexDirection: "unset !important",
    }),
  },

  theR: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // marginTop: '4rem',

    ...md("md", {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
  },

  theFlex: {
    display: "block",

    ...md("sm", {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      //   flexDirection: "column",
    }),
  },

  theBG: {
    position: "relative",
    width: "100%",
    backgroundImage: "url(" + bg001 + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",

    "& img": {
      width: "100%",
    },
  },

  theBG2: {
    position: "relative",
    width: "100%",
    backgroundImage: "url(" + bg002 + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",

    "& img": {
      width: "100%",
    },
  },

  theProdIMG: {
    position: "relative",
    width: "100%",
    margin: "0 auto",
    marginBottom: "1rem",
    // background: "#F2F2F2",
    padding: "1rem 0",

    "& img": {
      width: "80%",
      textAlign: "center",
      margin: "0 auto",
      display: "flex",
    },
  },

  theProdd: {
    position: "relative",
    width: "50%",
    margin: "0 auto",
    "& img": {
      width: "100%",
    },
  },

  theF: {
    position: "relative",
    width: "100%",
    marginBottom: "1rem",

    "& img": {
      width: "100%",
    },
  },

  theAb: {
    position: "absolute",
    width: "80%",
    top: "65%",
    left: "47%",
    transform: "translate(-50%, -50%)",
    margin: "0 auto",
  },

  theIcon: {
    position: "relative",
    width: "2.4vw",
    marginRight: "1rem",

    "& img": {
      width: "100%",
    },
  },

  theCard: {
    position: "relative",
    borderRadius: "6px",
    boxShadow: "0px 0px 9px 3px rgba(0, 0, 0, 0.2)",
    padding: "2rem 1rem",
    display: "block",
    cursor: "pointer",
    background: "#fff",
    borderBottom: "8px solid #BA2025",
    height: "250px",
    marginBottom: "1rem",
  },

  theIcon2: {
    position: "relative",
    width: "30%",
    marginBottom: "2rem",

    "& img": {
      width: "100%",
    },
  },

  theBG3: {
    position: "relative",
    width: "100%",
    backgroundImage: "url(" + bgred + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",

    "& img": {
      width: "100%",
    },
  },

  theIconn: {
    display: "flex",
    justifyContent: "flex-end",

    marginBottom: "2rem",

    "& img": {
      width: "30%",
    },
  },

  theRec: {
    position: "absolute",
    width: "90%",
    right: "0",
    transform: "translateY(200%)",
    margin: "0 auto",

    ...md("sm", {
      width: "55%",
      transform: "translateY(90%)",
    }),

    ...md("md", {
      width: "50%",
      transform: "translateY(45%)",
    }),

    ...md("xmd", {
      width: "40%",
      transform: "translateY(34%)",
    }),

    "& img": {
      width: "100%",
    },
  },

  theReview: {
    position: "relative",
    background: "#fff",
    borderRadius: "6px",
    width: "90% !important",
    margin: "0 auto",
    padding: "1rem",
    border: "3px solid #d7d7d7",

    // ...md("sm", {
    //     width: "100% !important",
    // }),

    ...md("md", {
      width: "80% !important",
    }),

    ...md("xmd", {
      width: "86% !important",
    }),
  },

  theOver: {
    right: "0",
    transform: "translateY(13%)",

    ...md("sm", {
      position: "absolute",

      top: "65%",
      transform: "translateY(-50%)",
    }),

    ...md("md", {
      top: "55%",
      transform: "translateY(-50%)",
      right: "-10%",
    }),

    ...md("xmd", {
      top: "55%",
      transform: "translateY(-50%)",
    }),

    ...md("lg", {
      top: "70%",
      transform: "translateY(-50%)",
    }),
  },

  theFlexQuantity: {
    position: "relative",
    alignItems: "center",
    display: "flex",
    margin: "1rem 0",
    border: "1px solid #BA2025",
    width: "60%",
  },

  theIncrement: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    width: "100%",
  },

  theButtonStyle: {
    color: "#fff",
    fontSize: "1.4rem",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  theBox: {
    position: "relative",
    width: "50%",
    borderRadius: "6px",
    padding: " 0.65rem 0",
  },

  theCount: {
    color: "#264653",
    fontWeight: "600",
    textAlign: "center",
    margin: 0,
  },

  theBtn: {
    position: "relative",
    margin: "21px 0 0 0",
  },

  // START NEW STYLES //
  _theIcon: {
    position: "relative",
    width: "18%",
    marginRight: "1.5rem",

    "& img": {
      width: "100%",
    },
  },
  _theCard: {
    position: "relative",
    padding: "3rem 2rem",
  },
  _theCardIn: {
    position: "relative",
    width: "91%",
    marginLeft: "4px",
    "& img": {
      width: "100%",
    },
  },
  _theCardIn2: {
    position: "relative",
    width: "87%",
    marginLeft: "18px",
    "& img": {
      width: "100%",
    },
  },
  _theCardIn3: {
    position: "relative",
    width: "88%",
    marginLeft: "12px",
    "& img": {
      width: "100%",
    },
  },
  _theF: {
    position: "relative",
    width: "100%",
    marginBottom: "1rem",
    "& img": {
      width: "100%",
    },
  },
  _theNum: {
    borderRadius: "50px",
    background: "#BA2025",
    color: "#FFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    fontWeight: "600",
    width: "25px",
    height: "25px",
    marginRight: "1rem",
    padding: "1rem",
  },
  _theDetail: {
    position: "relative",
    width: "80%",
    border: "1px solid #d7d7d7",
    borderRadius: "50px",
    boxShadow: "0px 3px 4px 1px rgba(0, 0, 0, .6)",
    padding: ".6rem 2rem",
  },

  _theReview: {
    position: "relative",
  },

  _theAvatar: {
    position: "relative",
    width: "400px",

    "& img": {
      width: "100%",
      height: "400px",
      objectFit: "cover",
    },
  },

  _theBox: {
    position: "relative",
    padding: "2rem 1rem",
    background: "#FFF",
    width: "100%",
  },

  _theIconn: {
    position: "relative",
    width: "20%",
    marginBottom: "14px",

    "& img": {
      width: "100%",
    },
  },

  _theWave: {
    position: "absolute",
    top: 0,
    right: 0,

    "& img": {
      width: "100%",
    },
  },
  _theMb: {
    marginBottom: "1rem !important",
    ...md("lg", {
      marginBottom: 0,
    }),
  },
  _theProdFloat: {
    width: "100%",
    ...md("xmd", {
      width: "auto",
    }),
  },

  _theShadow: {
    width: "100%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    marginTop: "-230px",
  },
  _theShadow2: {
    width: "100%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    marginTop: "-260px",
  },
  _theIMG: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },
  // END NEW STYLES //
};

export default makeStyles(config);
